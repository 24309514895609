import { dataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
const label = "颜色选择";
const name = "yes-colorPicker";

export default {
  icon: "yes-icon-color",
  label,
  name,
  rule() {
    return {
      type: name,
      field: uniqueId(),
      title: label,
      props: {
        dataSource:""
      },
    };
  },
  props() {
    return [
      dataSource(),
      {
        type: "switch",
        field: "disabled",
        title: "是否只读",
      },
      {
        type: "select",
        field: "size",
        title: "尺寸",
        options: [
          {
            label: "medium",
            value: "medium",
          },
          {
            label: "small",
            value: "small",
          },
          {
            label: "mini",
            value: "mini",
          },
        ],
      },
      {
        type: "switch",
        field: "showAlpha",
        title: "是否支持透明度选择",
      },
    ];
  },
};
