import { createName } from "../../utils";
import uniqueId from '@form-create/utils/lib/unique';

const label = "走马灯";
const name = "yes-carousel";

export default {
  icon: "yes-icon-carousel",
  label,
  name,
  mask: false,
  rule() {
    return {
      name: uniqueId(),
      type: name,
      title: label,
      props: {
        height: "100px",
        initialIndex: 1,
        interval: 1000,
        type: "",
      },
      children: [],
    };
  },
  children: "yes-carousel-item",
  props() {
    return [
      createName(),
      { type: "input", field: "height", title: "高度" },
      { type: "inputNumber", field: "initialIndex", title: "初始展示索引", props: { min: 1 } },
      { type: "switch", field: "autoplay", title: "是否自动播放" },
      { type: "inputNumber", field: "interval", title: "自动切换间隔", props: { min: 1000 } },
      { type: "switch", field: "loop", title: "是否循环播放" },
      { type: "select", field: "type", title: "类型", options: [{ label: "默认", value: "" }, { label: "卡片", value: "card" }] },
      { type: "select", field: "indicatorPosition", title: "指示器位置", options: [{ label: "outside", value: "outside" }, { label: "none", value: "none" }] },
      { type: "select", field: "arrow", title: "箭头的显示时机", options: [{ label: "always", value: "always" }, { label: "hover", value: "hover" }, {label: "never", value: "never"}] },
      { type: "select", field: "direction", title: "轮播方向", options: [{ label: "horizontal", value: "horizontal" }, { label: "vertical", value: "vertical" }] },
    ];
  },
};
