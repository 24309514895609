import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";

const label = "分页";
const name = "yes-pagination";

export default {
  icon: "yes-icon-pagination",
  label,
  name,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      title: label,
      props: {
        dataSource: "",
        hideOnSinglePage: false,
      },
    };
  },
  props() {
    // 表格配置
    return [
      createName(),
      dataSource(),
      {
        type: "input",
        field: "prevText",
        title: "替代图标显示的上一页文字",
      },
      {
        type: "input",
        field: "nextText",
        title: "替代图标显示的下一页文字",
      },
      {
        type: "yesStruct",
        field: "pageSizes",
        title: "每页显示个数选择器的选项设置",
        value: [10, 20, 30, 40, 50, 100],
      },
      {
        type: "input",
        field: "pagerCount",
        title: "页码按钮的数,当总页数超过该值时会折叠",
        info:'大于等于5且小于等于21的奇数'
      },
      {
        type: "input",
        field: "pageSize",
        title: "每页显示条目个数",
      },
      {
        type: "input",
        field: "layout",
        title: "组件布局，子组件名用逗号分隔",
        info: "有以下组件[prev, pager, next, jumper, ->,sizes, total,slot]",
      },
      {
        type: "switch",
        field: "background",
        title: "是否为分页按钮添加背景色",
      },
      {
        type: "switch",
        field: "disabled",
        title: "是否禁用",
      },
      {
        type: "switch",
        field: "small",
        title: "是否使用小型分页样式",
      },
      {
        type: "switch",
        field: "hideOnSinglePage",
        title: "只有一页时是否隐藏",
      },
      {
        type: "yesStruct",
        field: "sizeChangeEvent",
        title: "页面显示个数改变时触发事件",
        info: "参数(size, dao, api,utils)",
        props: {
          valueType: "function",
        },
      },
      {
        type: "yesStruct",
        field: "pageChangeEvent",
        title: "页数改变时触发事件",
        info: "参数(page, dao, api, utils)",
        props: {
          valueType: "function",
        },
      },
      {
        type: "yesStruct",
        field: "prevEvent",
        title: "点击上一页触发事件",
        info: "参数(page, dao, api,utils)",
        props: {
          valueType: "function",
        },
      },
      {
        type: "yesStruct",
        field: "nextEvent",
        title: "点击下一页触发事件",
        info: "参数(page, dao, api, utils)",
        props: {
          valueType: "function",
        },
      },
    ];
  },
};
