import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource, viewDataSource } from "../../utils";

const label = "树形控件";
const name = "yes-tree";

export default {
  icon: "yes-icon-tree",
  label,
  name,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      title: label,
      props: {
        dataSource: "",
        viewDataSource: "",
      },
    };
  },
  props() {
    // 表格配置
    return [
      createName(),
      dataSource(),
      viewDataSource(),
      {
        type: "input",
        field: "nodeId",
        title: "节点编号字段",
      },
      {
        type: "input",
        field: "nodeName",
        title: "节点名称字段",
      },
      {
        type: "input",
        field: "parentId",
        title: "上级节点编号字段",
      },
      {
        type: "switch",
        field: "defaultExpandAll",
        title: "是否默认展开所有节点",
      },
      {
        type: "switch",
        field: "expandOnClickNode",
        title: "是否在点击节点的时候展开或者收缩节点",
        value:true
      },
      {
        type: "switch",
        field: "autoExpandParent",
        title: "展开子节点的时候是否自动展开父节点",
        value:true
      },
      {
        type: "switch",
        field: "showCheckbox",
        title: "可选择",
      },
      {
        type: "switch",
        field: "highlight",
        title: "是否高亮点击的节点",
      },
      {
        type: "switch",
        field: "checkStrictly",
        title: "父子节点联动",
        info:"在显示复选框的情况下，是否严格的遵循父子不互相关联的做法，默认为 false",
      },
      {
        type: "switch",
        field: "isBaseSelect",
        title: "是否只选择根级",
        value: false
      },
      {
        type: "yesStruct",
        field: "treeClickEvent",
        title: "点击事件",
        info: "参数(checkedData, dao, api,utils)",
        props: {
          valueType: "function",
        },
      },
      {
        type: "yesStruct",
        field: "treeCheckEvent",
        title: "选中事件",
        info: "参数(checkedTreeData, checkedTree, dao, api, utils)",
        props: {
          valueType: "function",
        },
      },
    ];
  },
};
