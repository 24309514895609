import uniqueId from "@form-create/utils/lib/unique";
import { createName } from "../../utils";

const label = "播放器(rtsp)";
const name = "yes-video-rtsp";

export default {
  icon: "yes-icon-video",
  label,
  name,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      title: label,
      props: {
        width: "1366px",
        height: "750px",
      },
    };
  },
  props() {
    // 表格配置
    return [
      createName(),
      {
        type: "input",
        field: "width",
        title: "宽度",
      },
      {
        type: "input",
        field: "height",
        title: "高度",
      },
      {
        type: "input",
        field: "url",
        title: "链接地址",
      }
    ];
  },
};
