// import { createName } from "../../utils";
const label = "分割线";
const name = "yes-divider";

export default {
    icon: "yes-icon-divider",
    label,
    name,
    rule() {
        return {
            type: name,
            title: label,
            props: {},
        };
    },
    props() {
        return [
            // createName(),
            {
                type: "select",
                field: "direction",
                title: "分割线方向",
                options: [
                    { label: "水平", value: "horizontal" },
                    { label: "垂直", value: "vertical" },
                ],
            },
            {
                type: "select",
                field: "contentPosition",
                title: "分割线文案的位置",
                options: [
                    { label: "左", value: "left" },
                    { label: "中", value: "center" },
                    { label: "右", value: "right" },
                ],
            },
            { type: "input", field: "content", title: "文案", props: { type: "textarea", rows: 4 } },
            { type: "input", field: "customStyle", title: "自定义样式", props: { type: "textarea", rows: 4 } },
        ];
    },
};
