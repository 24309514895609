import { createName, dataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";

const label = "资源分配表";
const name = "yes-vis-timeline";

export default {
    icon: "yes-icon-timeline",
    label,
    name,
    mask: false,
    rule() {
        return {
            name: uniqueId(),
            type: name,
            title: label,
            props: {
                dataSource: "",
                initOpitions: {
                    editable: true,
                    orientation: "top"
                },
                itemFields: {
                    id: "$uid",
                    content: "$content",
                    start: "$start",
                    end: "${end}"
                },
                isStack: true,
                isStackSubgroups: true,
                groupFields: {
                    id: "$uid",
                    content: "$content"
                },
                addScript: "return true",
                updateScript: "return true",
                moveScript: "return true",
                removeScript: "return true",
                hoverTime: 1000,
                itemOverflow: false,
                itemVertical: 0,
                itemHorizontal: 0,
                groupItemMinHeight: 0
            }
        };
    },
    props() {
        return [
            createName(),
            dataSource(),
            {
                type: "yesStruct",
                field: "itemFields",
                title: "显示项参数配置",
                info: "含$代表引用显示项数据对应参数的值，否则为常量。其他可用参数参考https://visjs.github.io/vis-timeline/docs/timeline/#items",
                props: {
                    type: "textarea",
                    rows: 8
                },
                value: {
                    id: "$uid",
                    content: "$content",
                    start: "$start",
                    end: "${end}"
                }
            },
            {
                type: "inputNumber",
                field: "itemVertical",
                title: "项之间的最小垂直边距",
                info: "必须大于0, 0则默认为组件设定的默认值"
            },
            {
                type: "inputNumber",
                field: "itemHorizontal",
                title: "项之间的最小水平边距",
                info: "必须大于0, 0则默认为组件设定的默认值"
            },
            {
                type: "inputNumber",
                field: "groupItemMinHeight",
                title: "根据每个项定义组件最小高度",
                info: "必须大于0, 0则默认为组件设定的默认值"
            },
            {
                type: "input",
                field: "viewDataSource", //用于表格,树和表格
                title: "分组数据源"
            },
            {
                type: "yesStruct",
                field: "groupFields",
                title: "分组参数配置",
                info: "含$代表引用分组数据对应参数的值，否则为常量。其他可用参数参考https://visjs.github.io/vis-timeline/docs/timeline/#groups",
                props: {
                    type: "textarea",
                    rows: 8
                },
                value: {
                    id: "$uid",
                    content: "$content"
                }
            },
            {
                type: "yesStruct",
                field: "initOpitions",
                title: "组件参数配置",
                info: "可用参数参考https://visjs.github.io/vis-timeline/docs/timeline/#Configuration_Options",
                props: {
                    type: "textarea",
                    rows: 8
                },
                value: {
                    editable: true,
                    orientation: "top"
                }
            },
            {
                type: "yesStruct",
                field: "template",
                title: "组件显示模板",
                info: "html代码，{{value1}} 引用数据的变量，如 <td>{{value1}}</td>",
                props: {
                    valueType: "function"
                }
            },
            // {
            //   type: "yesStruct",
            //   field: "templatebind",
            //   title: "组件显示模板事件绑定",
            //   info:"通过document或取标签对象进事件绑定，如document.getElementById('btn').addEventListener('click',function(){alert(1)})",
            //   props: {
            //     valueType : 'function'
            //   }
            // },
            {
                type: "switch",
                field: "isStack",
                title: "是否项堆叠",
                info: "如果为true（默认值），项目将堆叠在一起，这样它们就不会重叠。"
            },
            {
                type: "switch",
                field: "isStackSubgroups",
                title: "是否子组项堆叠",
                info: "如果为true（默认值），子组将堆叠在一起，这样它们就不会重叠。"
            },
            {
                type: "yesStruct",
                field: "addScript",
                title: "新增脚本(双击空白处触发)",
                info: "item 为操作的对象",
                props: {
                    valueType: "function"
                },
                value: "return true"
            },
            {
                type: "yesStruct",
                field: "updateScript",
                title: "编辑脚本(双击任务时触发)",
                info: "item 为操作的对象",
                props: {
                    valueType: "function"
                },
                value: "return true"
            },
            {
                type: "yesStruct",
                field: "moveScript",
                title: "移动脚本(拖动任务后触发)",
                info: "item 为操作的对象， 结果 return true / return false （返回时为false时修改结果不生效）",
                props: {
                    valueType: "function"
                },
                value: "return true"
            },
            {
                type: "yesStruct",
                field: "removeScript",
                title: "删除脚本(删除任务后触发)",
                info: "item 为操作的对象， 结果 return true / return false （返回时为false时修改结果不生效）",
                props: {
                    valueType: "function"
                },
                value: "return true"
            },
            {
                type: "yesStruct",
                field: "clickScript",
                title: "左键单击时间线脚本",
                info: "vm, api, dao, utils, props, timeline, fvalue, items",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "inputNumber",
                field: "hoverTime",
                title: "悬停触发事件时间"
            },
            {
                type: "yesStruct",
                field: "itemOverScript",
                title: "鼠标悬停项目触发脚本",
                info: "vm, api, dao, utils, events, timeline, fvalue",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "itemOutScript",
                title: "鼠标移出项目触发脚本",
                info: "vm, api, dao, utils, events, timeline, fvalue",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "switch",
                field: "itemOverflow",
                title: "是否项文字溢出",
                info: "true=overflow: visible; false=overflow: hidden;"
            }
            // { type: 'select', field: 'type', title: '节点类型', options: [{value: '默认', label:'primary' }, {label: '成功', value: 'success'}, {label: '警告', value: 'warning'}, {label: '消息', value: 'info'}, {label: '错误', value: 'danger'}] },
            // { type: 'input', field: 'color', title: '节点颜色' },
            // { type: 'input', field: 'icon', title: '节点图标' },
        ];
    }
};
