export default function form() {
    return [
        {
            type: "input",
            field: "pageTitle",
            title: "页面标题",
            value: "",
        },
        {
            type: "radio",
            field: "labelPosition",
            value: "right",
            title: "标签位置",
            options: [
                { value: "right", label: "right" },
                { value: "left", label: "left" },
                { value: "top", label: "top" },
            ],
        },
        {
            type: "radio",
            field: "size",
            value: "mini",
            title: "表单尺寸",
            options: [
                { value: "medium", label: "medium" },
                { value: "small", label: "small" },
                { value: "mini", label: "mini" },
            ],
        },
        {
            type: "input",
            field: "labelWidth",
            value: "100px",
            title: "标签宽度",
        },
        {
            type: "input",
            field: "zoom",
            title: "页面缩放",
        },
        {
            type: "switch",
            field: "hideRequiredAsterisk",
            value: false,
            title: "隐藏必填字段的标签旁边的红色星号",
        },
        {
            type: "switch",
            field: "showMessage",
            value: true,
            title: "显示校验错误信息",
        },
        {
            type: "switch",
            field: "inlineMessage",
            value: false,
            title: "以行内形式展示校验信息",
        },
        {
            type: "switch",
            field: "formCreateSubmitBtn",
            value: false,
            title: "是否显示表单提交按钮",
        },
        {
            type: "switch",
            field: "formCreateResetBtn",
            value: false,
            title: "是否显示表单重置按钮",
        },
        {
            type: "yesStruct",
            field: "beforeCreateScript",
            title: "加载前脚本",
            value: "",
            props: {
                valueType: "function",
            },
        },
        {
            type: "yesStruct",
            field: "afterCreateScript",
            title: "加载后脚本",
            value: "",
            props: {
                valueType: "function",
            },
        },
        {
            type: "yesStruct",
            field: "iframeReturnScript",
            title: "页面消息处理脚本",
            info: "参数(data,vm,api,dao, utils),iframe回传数据执行脚本",
            value: "",
            props: {
                valueType: "function",
            },
        },
        {
            type: "input",
            field: "definedCss",
            title: "自定义样式",
            value: "",
            props: {
                type: "textarea",
                rows: 8,
            },
        },
        {
            type: "input",
            field: "printCss",
            title: "打印样式",
            info: "size:21cm 29.7cm!important; margin: 0;",
            value: "",
            props: {
                type: "textarea",
                rows: 8,
            },
        },
    ];
}
