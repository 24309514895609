import uniqueId from '@form-create/utils/lib/unique';


const label = '面包屑item';
const name = 'yes-breadcrumb-item';

export default {
    label,
    name,
    inside: true,
    drag: true,
    dragBtn: false,
    // smask:false,
    // children: 'yes-div',
    rule() {
        return {
            type: 'yes-breadcrumb-item',
            name: uniqueId(),
            title: label,
            props: {},
            children: []
        };
    },
    props() {
        return [
            { type: 'input', field: 'label', title: '面包屑名称' },
            { type: 'input', field: 'to', title: '面包屑跳转' },
            { type: 'switch', field: 'replace', title: '是否添加路由记录' },
        ];
    }
};
