import { dataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";

const label = "计数器";
const name = "yes-inputNumber";

export default {
  icon: "yes-icon-inputNumber",
  label,
  name,
  rule() {
    return {
      field: uniqueId(),
      type: name,
      title: label,
      props: {
        dataSource: "",
      },
    };
  },
  props() {
    return [
      dataSource(),
      {
        type: "inputNumber",
        field: "min",
        title: "最小值",
      },
      {
        type: "inputNumber",
        field: "max",
        title: "最大值",
      },
      {
        type: "input",
        field: "placeholder",
        title: "计数框占位文本",
      },
      {
        type: "inputNumber",
        field: "step",
        title: "计数器步长",
      },
      {
        type: "inputNumber",
        field: "precision",
        title: "计数器精度",
      },
      {
        type: "switch",
        field: "disabled",
        title: "是否只读",
      },
      {
        type: "select",
        field: "size",
        title: "尺寸",
        options: [
          {
            label: "medium",
            value: "medium",
          },
          {
            label: "small",
            value: "small",
          },
          {
            label: "mini",
            value: "mini",
          },
        ],
      },
      {
        type: "switch",
        field: "controls",
        title: "是否使用控制按钮",
      },
      {
        type: "input",
        field: "label",
        title: "输入框关联的label文字",
      },
    ];
  },
};
