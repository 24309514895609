import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";
const label = "mermaid";
const name = "yes-mermaid";

export default {
  icon: "yes-icon-table",
  label,
  name,
  mask: false,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      props: {
        dataSource:""
      },
      children: [],
    };
  },
  // children: "",
  props() {
    return [
      createName(),
      dataSource()
    ];
  },
};
