import { createName, dataSource, viewDataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
const label = "表格";
const name = "yes-table";

export default {
    icon: "yes-icon-table",
    label,
    name,
    children: "yes-table-column",
    mask: false,
    rule() {
        return {
            type: name,
            name: uniqueId(),
            title: label,
            props: {
                dataSource: "",
                viewDataSource: "",
                selectOnIndeterminate: true
            },
            children: []
            // options: [
            //     {value: 'field1', label: '选项1'},
            //     {value: 'field1', label: '选项2'}],
        };
    },
    props() {
        // 表格配置
        return [
            createName(),
            dataSource(),
            viewDataSource(),
            {
                type: "input",
                field: "indexKey",
                title: "表单主键",
                info: "有主键就可以使用对象数组给数据源赋值"
            },
            // makeOptionsRule('options'),
            {
                type: "switch",
                field: "showCheckbox",
                title: "可选择"
            },
            {
                type: "switch",
                field: "multiSelect",
                title: "是否多选"
            },
            {
                type: "input",
                field: "height",
                title: "高度"
            },
            {
                type: "input",
                field: "maxHeight",
                title: "最大高度"
            },
            { type: "switch", field: "stripe", title: "是否为斑马纹" },
            { type: "switch", field: "border", title: "是否显示边框" },
            {
                type: "select",
                field: "size",
                title: "Table 的尺寸",
                options: [
                    { label: "无", value: "" },
                    { label: "medium", value: "medium" },
                    { label: "small", value: "small" },
                    { label: "mini", value: "mini" }
                ]
            },
            { type: "switch", field: "fit", value: true, title: "是否自撑开" },
            {
                type: "switch",
                field: "showHeader",
                value: true,
                title: "是否显示表头"
            },
            {
                type: "switch",
                field: "highlightCurrentRow",
                value: false,
                title: "是否高亮当前行"
            },
            {
                type: "input",
                field: "currentRowKey",
                title: "当前行的 key"
            },
            {
                type: "yesStruct",
                field: "rowClassName",
                title: "行的 class 名称",
                info: "参数(param={row,rowIndex},vm,api,utils)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "rowStyle",
                title: "行的样式",
                info: "参数(param={row,rowIndex},vm,api,utils)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "cellClassName",
                title: "单元格的 class 名称",
                info: "参数(param={row,column,rowIndex,columnIndex},vm,api,utils)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "cellStyle",
                title: "单元格的样式",
                info: "参数(param={row,column,rowIndex,columnIndex},vm,api,utils)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "headerRowClassName",
                title: "表头行的 class 名称",
                info: "参数(param={row,rowIndex},vm,api,utils)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "headerRowStyle",
                title: "表头行的样式",
                info: "表头一整行的样式, 参数(param={row,rowIndex},vm,api,utils)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "headerCellClassName",
                title: "表头单元格的 class 名称",
                info: "参数(param={row,column,rowIndex,columnIndex},vm,api,utils)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "headerCellStyle",
                title: "表头[单元格]的样式的计算方法",
                info: "表头每一个单元格的样式, 参数(param={row,column,rowIndex,columnIndex},vm,api,utils)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "input",
                field: "emptyText",
                title: "空内容时显示的文本"
            },
            {
                type: "yesStruct",
                field: "defaultSort",
                title: "默认排序",
                props: {}
            },
            {
                type: "input",
                field: "tooltipEffect",
                title: "单元格的 tooltip 效果"
            },
            { type: "switch", field: "showSummary", title: "是否显示合计行", info: "要显示合计行，合计行的计算方法必须写" },
            {
                type: "yesStruct",
                field: "summaryMethod",
                title: "合计行的计算方法",
                info: "参数(param={columns,data},vm,api,utils)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "spanMethod",
                title: "合并行或列的计算方法",
                info: "参数(param={row,column,rowIndex,columnIndex},vm,api,utils)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "switch",
                field: "selectOnIndeterminate",
                title: "部分行被选中，点击表头多选框的行为",
                info: "在多选表格中，当仅有部分行被选中时，点击表头的多选框时的行为。若为 true，则选中所有行；若为 false，则取消选择所有行"
            },
            {
                type: "input",
                field: "indent",
                title: "展开行的缩进，以 px 为单位"
            },
            {
                type: "switch",
                field: "enableTree",
                title: "是否启用树形表格"
            },
            { type: "switch", field: "defaultExpandAll", title: "是否默认展开", info: "当Table包含展开行存在或者为树形表格时有效" },
            {
                type: "input",
                field: "nodeId",
                title: "节点编号字段",
                info: "启用树形表格必填"
            },
            {
                type: "input",
                field: "parentId",
                title: "上级节点编号字段",
                info: "启用树形表格必填"
            },
            {
                type: "input",
                field: "rowKey",
                title: "行key的参数(必须唯一)",
                info: "启用树形表格必填, 或是在表格列存在多选框需要赋值勾选时"
            },
            { type: "switch", field: "lazy", title: "是否懒加载" },
            {
                type: "input",
                field: "hasChildren",
                title: "判断是否存在子项的字段",
                info: "开启懒加载后必填"
            },
            {
                type: "yesStruct",
                field: "loadEvent",
                title: "开启懒加载,加载数据的方法,通过resolve([])的方式渲染子节点",
                info: "参数(tree, treeNode, resolve,api, dao, utils)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "switch",
                field: "eventFlag",
                title: "单击事件/双击事件"
            },
            {
                type: "yesStruct",
                field: "rowClickEvent",
                title: "行单击事件",
                info: "参数(row,api, dao, utils)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "rowDblclickEvent",
                title: "行双击事件",
                info: "参数(row,api, dao, utils)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "checkEvent",
                title: "选中事件",
                info: "参数(selection,api, dao, utils)",
                props: {
                    valueType: "function"
                }
            }
        ];
    }
};
