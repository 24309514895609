import { dataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
const label = "上传";
const name = "yes-upload";

export default {
    icon: "yes-icon-upload",
    label,
    name,
    rule() {
        return {
            field: uniqueId(),
            type: name,
            title: label,
            props: {
                multiple: false,
                showFileList: true,
                listType: "text",
                autoUpload: true,
                disabled: false,
                dataSource: "",
                compressionThreshold: "",
                isEn: false
            }
        };
    },
    props() {
        return [
            dataSource(),
            {
                type: "input",
                field: "app",
                title: "模型应用编号(必填)"
            },
            {
                type: "input",
                field: "model",
                title: "模型编号(必填)"
            },
            // {
            //   type: "input",
            //   field: "uid",
            //   title: "模型实例主键(必填)",
            // },
            {
                type: "input",
                field: "modelField",
                title: "模型字段"
            },
            {
                type: "input",
                field: "modelUid",
                title: "模型实例UID"
            },
            {
                type: "inputNumber",
                field: "imageQuality",
                title: "图片质量(默认0.7)"
            },
            {
                type: "inputNumber",
                field: "compressionThreshold",
                title: "图片超过多少压缩(M)"
            },
            // {
            //   type: 'select',
            //   field: 'uploadType',
            //   title: '上传类型',
            //   value: 'image',
            //   options: [{
            //     label: '图片',
            //     value: 'image'
            //   }, {
            //     label: '文件',
            //     value: 'file'
            //   }]
            // },
            // {
            //   type: 'input',
            //   field: 'action',
            //   title: '上传的地址(必填)'
            // },
            // {
            //   type: 'Struct',
            //   field: 'headers',
            //   title: '设置上传的请求头部',
            //   props: {
            //     defaultValue: {}
            //   }
            // },
            {
                type: "input",
                field: "customerAname",
                title: "自定义上传按钮名称"
            },

            {
                type: "switch",
                field: "multiple",
                title: "是否支持多选文件"
            },
            {
                type: "switch",
                field: "showFileList",
                title: "是否显示已上传文件列表"
            },

            {
                type: "select",
                field: "listType",
                title: "文件列表的类型",
                options: [
                    {
                        label: "text",
                        value: "text"
                    },
                    {
                        label: "picture",
                        value: "picture"
                    },
                    {
                        label: "picture-card",
                        value: "picture-card"
                    }
                ]
            },
            // {
            //   type: 'Struct',
            //   field: 'paramData',
            //   title: '上传时附带的额外参数',
            //   props: {
            //     defaultValue: {}
            //   }
            // },
            // {
            //   type: 'input',
            //   field: 'name',
            //   title: '上传的文件字段名'
            // }, {
            //   type: 'switch',
            //   field: 'withCredentials',
            //   title: '支持发送 cookie 凭证信息'
            // },
            {
                type: "input",
                field: "accept",
                title: "接受上传的文件类型（thumbnail-mode 模式下此参数无效）"
            },
            {
                type: "switch",
                field: "autoUpload",
                title: "是否在选取文件后立即进行上传"
            },
            {
                type: "switch",
                field: "disabled",
                title: "是否禁用"
            },
            {
                type: "switch",
                field: "isEn",
                title: "是否开启英文提示"
            },
            {
                type: "inputNumber",
                field: "limit",
                title: "最大允许上传个数"
            },
            {
                type: "yesStruct",
                field: "uploadSuccessEvent",
                title: "上传成功事件（参数:vm\response",
                props: {
                    valueType: "function"
                }
            }
        ];
    }
};
