import uniqueId from '@form-create/utils/lib/unique';
import { createName, dataSource } from "../../utils";
const label = '腾讯地图';
const name = 'yes-tencent-map';

export default {
    icon: 'yes-icon-tag',
    label,
    name,
    rule() {
        return {
            type: name,
            name: uniqueId(),
            title: label,
            info: '',
            props: {
                initLng: 118.08891,
                initLat: 24.479627,
                region: '',
                regionFix: false,
                searchPageSize: 10,
                placeholder: '请输入详细地址查找',
                isAutoInit: true,
                setTimeoutTime: 2000,
            },
        };
    },
    props() {
        return [
            createName(),
            dataSource(),
            { type: 'input', field: 'initLng', title: '经度', info: '初始化中心点经度' },
            { type: 'input', field: 'initLat', title: '经度', info: '初始化中心点纬度' },
            { type: 'input', field: 'region', title: '限制城市范围', info: '例: 福建、厦门; 放空且[不固定在当前城市] 范围=全国' },
            { type: 'input', field: 'setTimeoutTime', title: '延迟初始化时间', info: '延迟初始化时间, 防止页面未渲染就加载地图组件出现报错, 根据页面复杂度设置, 在初始化时才生效' },
            {
                type: 'select', field: 'regionFix', title: '是否固定在当前城市', info: '搜索无结果时是否固定在当前城市',
                options: [{ label: '固定', value: true }, { label: '不固定', value: false }]
            },
            {
                type: 'select', field: 'isAutoInit', title: '是否初始化', info: 'map组件初始化不是在当前tab页, 会出现问题, 将其设为false, 然后在对应页面再进行初始化',
                options: [{ label: '初始化', value: true }, { label: '不初始化', value: false }]
            },
            { type: 'input', field: 'searchPageSize', title: '搜索结果条目数', info: '输入框下拉选择条数' },
            { type: 'input', field: 'placeholder', title: '输入框提示文字' },
            {
                type: "yesStruct", field: "onChangeEvent", title: "输入框值变更事件", props: { valueType: 'function' }, info: '参数(vm, api, dao, utils, resultLocation)'
            },
            { type: 'input', field: 'tip', title: '数据源对象示例', info: "{'id': '唯一', 'address': '详细地址', 'title': '地点名称', 'lng': 'float number 经度', 'lat': 'float number 纬度', locationInfo: 'map对象, 包含lng 和 lat'" },
            { type: 'input', field: 'tip', title: '使用说明', info: "如果页面加载时地图就在当前tab中, 选中自动初始化; 如果不是, 1. 选中不初始化; 2. 在其他tab中, 先用一个div把地图放进去, 把div隐藏; 3. 创建一个按钮(打开地图); 4. 在按钮点击事件中 先show div, 然后setTimeout 1.5s 地图的初始化事件(initTencentMap) " },
            { type: 'input', field: 'tip', title: '使用示例', info: `const loadingDom = utils.loading("loading...", "rgba(0, 0, 0, 0.7)"); utils.exec("tencentMap_div", "show"); setTimeout(() => { utils.exec("F7wx5zgyejqxv", "initTencentMap"); }, 1500); setTimeout(() => { loadingDom.close(); }, 2000);` },
        ];
    }
};