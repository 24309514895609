import uniqueId from '@form-create/utils/lib/unique';
import { createName } from '../../utils';
const label = '步骤条';
const name = 'yes-steps';

export default {
  icon: 'yes-icon-steps',
  label,
  name,
  mask: false,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      props: {},
      children: [],
    };
  },
  children: 'yes-step',
  props() {
    return [
      createName(),
      {
        type: 'input',
        field: 'space',
        title: '间距',
        porps: {
          placeholder: '每个 step 的间距，不填写将自适应间距。支持百分比。',
        },
      },
      {
        type: 'select',
        field: 'direction',
        title: '方向',
        options: [
          { label: '水平', value: 'horizontal' },
          { label: '垂直', value: 'vertical' },
        ],
      },
      {
        type: 'inputNumber',
        field: 'active',
        title: '激活的步骤',
        porps: { placeholder: '激活的步骤，从 0 开始。' },
      },
      {
        type: 'select',
        field: 'processStatus',
        title: '当前步骤的状态',
        options: [
          { label: '默认', value: 'wait' },
          { label: '正常', value: 'process' },
          { label: '成功', value: 'success' },
          { label: '错误', value: 'error' },
          { label: '完成', value: 'finish' },
        ],
      },
      {
        type: 'select',
        field: 'finishStatus',
        title: '完成状态',
        options: [
          { label: '默认', value: 'wait' },
          { label: '正常', value: 'process' },
          { label: '成功', value: 'success' },
          { label: '错误', value: 'error' },
          { label: '完成', value: 'finish' },
        ],
      },
      { type: 'switch', field: 'alignCenter', title: '是否居中' },
      { type: 'switch', field: 'simple', title: '是否简洁模式' },
    ];
  },
};
