import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";
const label = "阿里播放器";
const name = "yesAliPlayer";

export default {
    icon: "yes-icon-video",
    label,
    name,
    mask: false,
    rule() {
        return {
            type: name,
            name: uniqueId(),
            props: {
                delayTime: 6,
                width: "100%",
                dataSource: "",
                preLoad: false,
                height: "500px",
                isEnglish: false,
                delayTimeMultiple: 1.3,
                autoPlayDelayDisplayText: ".",
            },
            children: [],
        };
    },
    props() {
        return [
            createName(),
            dataSource(),
            {
                type: "select",
                field: "isEnglish",
                title: "提示文字是否为英语",
                info: "是: 显示英语提示, 否: 显示中文提示",
                options: [
                    { label: "是", value: true },
                    { label: "否", value: false },
                ],
            },
            {
                type: "select",
                field: "preLoad",
                title: "是否预加载",
                info: "",
                options: [
                    { label: "是", value: true },
                    { label: "否", value: false },
                ],
            },
            { type: "inputNumber", field: "delayTime", title: "延迟秒数播放" },
            { type: "input", field: "width", title: "播放器宽度" },
            { type: "input", field: "height", title: "播放器高度" },
            { type: "inputNumber", field: "delayTimeMultiple", title: "延迟播放延迟系数", info: "延迟秒数播放 * 系数 = 真正延时的时间" },
            { type: "input", field: "autoPlayDelayDisplayText", title: "延时播放提示文字" },
        ];
    },
};
