import { dataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
const label = "开关";
const name = "yes-switch";

export default {
  icon: "yes-icon-switch",
  label,
  name,
  rule() {
    return {
      type: name,
      field: uniqueId(),
      title: label,
      props: {
        dataSource:""
      },
    };
  },
  props() {
    return [
      dataSource(),
      // {
      //   type: "input",
      //   field: "initColField",
      //   title: "初始化列字段",
        
      // },
      {
        type: "input",
        field: "openColor",
        title: "选中时展示颜色",
      },
      {
        type: "switch",
        field: "disabled",
        title: "是否只读",
      },
      {
        type: "input",
        field: "closeColor",
        title: "未选中时展示颜色",
      },
      {
        type: "input",
        field: "openText",
        title: "选中时展示文字",
      },
      {
        type: "input",
        field: "closeText",
        title: "未选中时展示文字",
      },
      {
        type: "yesStruct",
        field: "changeEvent",
        title: "change事件",
        props: {
          valueType: "function",
        },
      },
    ];
  },
};
