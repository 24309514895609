import { dataSource, viewDataSource, makeOptionsRule } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
import { getOptions } from "../../utils/classCss";
const label = "单选按钮框";
const name = "yes-radio-button";

export default {
    icon: "yes-icon-radio",
    label,
    name,
    rule() {
        return {
            type: name,
            field: uniqueId(),
            title: label,
            props: {
                dataSource: "",
                viewDataSource: "",
                defaultOptios: [],
            },
        };
    },
    props() {
        return [
            dataSource(),
            viewDataSource(),
            {
                type: "switch",
                field: "disabled",
                title: "是否只读",
            },
            {
                type: "input",
                field: "itemKey",
                title: "单选框键字段(:key)",
            },
            {
                type: "input",
                field: "itemLable",
                title: "单选框绑定值(:label)",
            },
            {
                type: "input",
                field: "itemValue",
                title: "单选框显示字段(:value)",
            },
            makeOptionsRule([
                { seq: 0, field: "defaultOptios", defaultShow: true },
                { seq: 1, field: "defaultOptios" },
            ]),
            {
                type: "select",
                field: "size",
                title: "尺寸",
                options: [
                    {
                        label: "medium",
                        value: "medium",
                    },
                    {
                        label: "small",
                        value: "small",
                    },
                    {
                        label: "mini",
                        value: "mini",
                    },
                ],
            },
            {
                type: "select",
                field: "titleCss",
                title: "标题样式",
                options: getOptions(),
            },
            {
                type: "select",
                field: "contentCss",
                title: "内容样式",
                options: getOptions(),
            },
            {
                type: "yesStruct",
                field: "definedCss",
                title: "自定义样式",
                props: {
                    type: "textarea",
                    rows: 8,
                },
            },
            {
                type: "yesStruct",
                field: "changeEvent",
                title: "change事件",
                props: {
                    valueType: "function",
                },
            },
        ];
    },
};
