import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";
const label = "图标";
const name = "yes-icon";

export default {
  icon: "yes-icon-icon",
  label,
  name,
  mask: false,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      props: {
        dataSource:""
      },
      children: [],
    };
  },
  // children: "",
  props() {
    return [
      createName(),
      dataSource(),
      { type: 'input', field: 'picture', title: '图标' },
      { type: 'input', field: 'colorDefault', title: '颜色' },
      { type: 'input', field: 'fSize', title: '大小' },
      {
        type: "yesStruct",
        field: "clickEvent",
        title: "点击事件",
        props: {
          valueType : 'function'
        }
      },
      {
        type: "input",
        field: "titleh",
        title: "提示文字",
      },
    ];
  },
};
