import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";

const label = "导航菜单";
const name = "yes-menu";

export default {
    icon: 'yes-icon-menu',
    label,
    name,
    rule() {
        return {
            type: name,
            name: uniqueId(),
            title: label,
            props: {
                dataSource:'',
            }
        };
    },
    props() { // 表格配置
        return [
            createName(),
            dataSource(),{
            type: 'input',
            field: 'nodeId',
            title: '节点编号字段',
            info:'菜单唯一标志'
        },{
            type: 'input',
            field: 'nodeName',
            title: '节点名称字段',
            info:'菜单名称'
        },
        {
            type: 'input',
            field: 'parentId',
            title: '上级节点编号字段',
        },
        {
            type: 'input',
            field: 'sort',
            title: '排序字段和规则',
            info:'如: seq,asc (asc升序,desc降序,只支持单字段,且为数字类型)'
        },
        {

            type: 'select',
            field: 'mode',
            title: '水平/侧边',
            value:'vertical',
            options: [
                {label: '水平导航栏', value: 'horizontal'}, 
                {label: '侧边导航栏', value: 'vertical'}, 
            ]
        },
        {
          type: 'input',
          field: 'icon',
          title: '图标字段',
          info:'菜单图标'
      },
      {
        type: 'input',
        field: 'menuWidth',
        title: '菜单栏宽度(%/px)',
        },
          {
            type: 'input',
            field: 'activeIndex',
            title: '默认要展示的最低级菜单',
            info:'节点编号字段对应的值'
        },{
          type: 'input',
          field: 'backgroundColor',
          title: '菜单的背景色',
          info:'(仅支持 hex 格式)'
      },{
        type: 'input',
        field: 'textColor',
        title: '菜单的文字颜色',
        info:'(仅支持 hex 格式)'
      },{
        type: 'input',
        field: 'activeTextColor',
        title: '当前激活菜单的文字颜色',
        info:'(仅支持 hex 格式)'
      },
        {
            type: 'yesStruct',
            field: 'selectMethodBody',
            title: '点击菜单事件',
            info:'参数(index,data, dao, api,utils),仅针对无子节点的菜单',
            props: {
                valueType : 'function'
              }

        },{
            type: 'yesStruct',
            field: 'onMethodBody',
            title: '展开菜单事件(仅对侧边栏生效)',
            info:'参数(index,data, dao, api, utils),仅针对有子节点的菜单',
            props: {
                valueType : 'function'
              }

        },{
          type: 'yesStruct',
          field: 'closeMethodBody',
          title: '收起菜单事件(仅对侧边栏生效)',
          info:'参数(index,data, dao, api, utils),仅针对有子节点的菜单',
          props: {
            valueType : 'function'
          }

      },{
        type: 'input',
        field: 'spmenuitem',
        title: '类名',
      }];
    }
};
