const menus = [
    {
        name: "layout",
        title: "布局组件",
        import: ["yesRow", "yesTabs", "yesDiv", "yesCollapse", "yesDrawer"],
        list: []
    },
    {
        name: "show",
        title: "展示组件",
        import: [
            "yesText",
            "yesImage",
            "yesTag",
            "yesLink",
            "yesDropdown",
            "yesIcon",
            "yesAvatar",
            "yesDivider",
            "yesProgress",
            "yesCarousel",
            "yesTimeline",
            "yesVisTimeline",
            "yesSteps",
            "yesBreadcrumb",
            "yesEmpty",
            "yesResult",
            "yesBadge",
            "yesIframe",
            "yesVideoRtsp",
            "yesFlv",
            "yesWorkbook",
            "yesWebDatarocks",
            "yesWebDataHighcharts",
            "yesCalendar",
            "yesHtml",
            "yesPivottable",
            "yesMarkdown",
            "yesBarCode",
            "yesQrCode",
            "yesTencentMap",
            "yesAliPlayer",
            "yesItemCalendar",
            "yesAudio"
        ],
        list: []
    },
    {
        name: "form",
        title: "表单组件",
        import: ["yesInput", "yesSwitch", "yesInputNumber", "yesSelect", "yesRadio", "yesRadioButton", "yesCheckbox", "yesCascader", "yesAreaSelect", "yesSlider", "yesRate", "yesDatepicker", "yesTimepicker", "yesTransfer", "yesColorPicker", "yesUpload", "yesMonacoEdit", "yesEditor", "yesViewSelector", "yesTransferTable"],
        list: []
    },
    {
        name: "source",
        title: "数据组件",
        import: ["yesDataSource", "yesPagination", "yesTable", "yesTree", "yesMenu", "yesDataArray", "yesDataObject", "yesGantt"],
        list: []
    },
    {
        name: "control",
        title: "控制组件",
        import: ["yesDialog", "yesComponent", "yesButton"],
        list: []
    },
    {
        name: "analyze",
        title: "分析组件",
        import: ["yesGaugeChart", "yesLineChart", "yesPieChart", "yesHistogram", "yesScatterPlot", "yesRadar"],
        list: []
    }
];

const path = require("path");
const files = require.context("../config/rule", false, /.js$/);

export default function createMenu() {
    menus.forEach((item) => {
        item.list = [];
        item.import.map((itemKey) => {
            files.keys().map((key) => {
                if (path.basename(key, ".js") == itemKey) {
                    item.list.push(files(key).default || files(key));
                }
            });
        });
        // item.list= files.keys().filter(key=>item.import.indexOf(path.basename(key, '.js'))>-1).map(key=>files(key).default || files(key))
    });
    return menus;
}
