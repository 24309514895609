import { createName } from '../../utils';
import uniqueId from '@form-create/utils/lib/unique';
const label = '子组件';
const name = 'yes-component';

export default {
  icon: "yes-icon-component",
  label,
  name,
  drag: true,
  mask: false,
  rule() {
    return {
      // field: uniqueId(),
      name: uniqueId(),
      type: name,
      children: [],
      props: {
        initLoading: true
      },

    };
  },
  props() {
    return [
      createName(),
      // {type: 'input', field: 'dataSource', title: '数据源'},
      // { type: "text", field: "description", title: "方法", info: "①提供setRule(rule=[]),覆盖rule; \n②提供addRule(rule=[]),添加rule" },
      { type: "input", field: "app", title: "应用编号" },
      { type: "input", field: "code", title: "组件编号" },
      { type: "switch", field: "initLoading", title: "是否默认加载" },
      {
        type: 'yesStruct',
        field: 'afterCreateScript',
        title: '加载后脚本',
        value: '',
        props: {
          valueType: 'function'
        }
      },
    ];
  },
};
