import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";
const label = "音频";
const name = "yesAudio";

export default {
    icon: "yes-icon-video",
    label,
    name,
    mask: false,
    rule() {
        return {
            type: name,
            props: {
                loop: false,
                muted: false,
                width: "100%",
                height: "100%",
                controls: true,
                autoplay: false,
                srcType: "link",
                customStyle: ""
            },
            children: []
        };
    },
    props() {
        return [
            createName(),
            { type: "input", field: "src", title: "播放源" },
            {
                type: "select",
                field: "srcType",
                title: "播放源类型",
                options: [
                    { label: "链接", value: "link" },
                    { label: "本地", value: "local" }
                ]
            },
            {
                type: "select",
                field: "loop",
                title: "是否循环播放当前音频",
                options: [
                    { label: "是", value: true },
                    { label: "否", value: false }
                ]
            },
            {
                type: "select",
                field: "muted",
                title: "是否静音",
                options: [
                    { label: "是", value: true },
                    { label: "否", value: false }
                ]
            },
            {
                type: "select",
                field: "controls",
                title: "是否显示控件",
                options: [
                    { label: "是", value: true },
                    { label: "否", value: false }
                ]
            },
            {
                type: "select",
                field: "autoplay",
                title: "是否自动播放",
                options: [
                    { label: "是", value: true },
                    { label: "否", value: false }
                ]
            },
            { type: "input", field: "customStyle", title: "播放器样式" },
            { type: "input", field: "width", title: "播放器宽度" },
            { type: "input", field: "height", title: "播放器高度" },
            {
                type: "yesStruct",
                field: "endEvent",
                title: "播放结束事件",
                info: "[@end]播放音频结束时触发",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "playEvent",
                title: "播放开始事件",
                info: "[@play]音频开始播放时触发",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "pauseEvent",
                title: "播放暂停事件",
                info: "[@pause]音频暂停播放时触发",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "timeUpdateEvent",
                title: "播放进度更改时间",
                info: "[@timeupdate]当目前的播放位置已更改时触发",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "loadedmetadataEvent",
                title: "加载元数据事件",
                info: "[@loadedmetadata]浏览器已加载音频/视频的元数据时触发",
                props: {
                    valueType: "function"
                }
            }
        ];
    }
};
