import uniqueId from '@form-create/utils/lib/unique';
import { createName } from '../../utils';
const label = '子步骤条';
const name = 'yes-step';

export default {
  label,
  name,
  drag: true,
  dragBtn: false,
  inside: true,
  // mask: true,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      props: {},
      children: [],
    };
  },
  props() {
    return [
      createName(),
      {
        type: 'input',
        field: 'title',
        title: '标题',
        porps: { placeholder: '请输入标题' },
      },
      {
        type: 'input',
        field: 'description',
        title: '描述',
        porps: { placeholder: '请输入描述', type: 'textarea', rows: 8 },
      },
      {
        type: 'input',
        field: 'icon',
        title: '图标',
        porps: { placeholder: '请输入图标' },
      },
      {
        type: 'select',
        field: 'status',
        title: '当前步骤的状态',
        options: [
          { label: '默认', value: 'wait' },
          { label: '正常', value: 'process' },
          { label: '成功', value: 'success' },
          { label: '错误', value: 'error' },
          { label: '完成', value: 'finish' },
        ],
      },
    ];
  },
};
