import { createName } from '../../utils';
import uniqueId from '@form-create/utils/lib/unique';
const name = 'yes-carousel-item';

export default {
    name,
    drag: true,
    dragBtn: false,
    inside: true,
    mask: false,
    rule() {
        const id = uniqueId();
        return {
            type: "el-carousel-item",
            name: id,
            props: {
                name: id,
                title: '新标题',
            },
            children: []
        };
    },
    props() {
        return [
            createName(),
            { type: 'input', field: 'name', title: '唯一标志符' },
            { type: 'input', field: 'title', title: '幻灯片标题' },
        ];
    }
};
