import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";
const label = "警告文本";
const name = "yes-alert";

export default {
  icon: "yes-icon-warning",
  label,
  name,
  mask: false,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      props: {
        type: "success",
        effect: "dark",
        dataSource:""
      },
      children: [],
    };
  },
  // children: "",
  props() {
    return [
      createName(),
      dataSource(),
      { type: 'select', field: 'type', title: '类型', options: [{label: '成功', value: 'success'}, {label: '警告', value: 'warning'}, {label: '消息', value: 'info'}, {label: '错误', value: 'error'}] },
      { type: 'switch', field: 'showIcon', title: '是否显示图标' },
      { type: 'switch', field: 'center', title: '是否居中' },
      { type: 'select', field: 'effect', title: '动画效果', options: [{label: 'light', value: 'light'}, {label: 'dark', value: 'dark'}] },
    ];
  },
};
