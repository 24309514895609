import { createName, dataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
const label = "下拉菜单";
const name = "yes-dropdown";

export default {
    icon: "yes-icon-dropdown",
    label,
    name,
    rule() {
        return {
            type: name,
            name: uniqueId(),
            title: label,
            info: "",
            props: {
                optionSource: "",
                dataSource: "",
                // triggerType:"click"
            },
        };
    },
    props() {
        return [
            createName(),
            dataSource(),
            {
                type: "input",
                field: "optionSource",
                title: "选项绑定数据",
            },
            {
                type: "yesStruct",
                field: "kuList",
                title: "自定义选项数据",
            },
            {
                type: "input",
                field: "drpValue1",
                title: "自定义菜单显示数据",
            },
            {
                type: "select",
                field: "triggerType",
                title: "下拉行为",
                options: [
                    {
                        label: "click",
                        value: "click",
                    },
                    {
                        label: "hover",
                        value: "hover",
                    },
                ],
            },
            {
                type: "select",
                field: "placement",
                title: "菜单弹出位置",
                options: [
                    {
                        label: "top",
                        value: "top",
                    },
                    {
                        label: "top-start",
                        value: "top-start",
                    },
                    {
                        label: "top-end",
                        value: "top-end",
                    },
                    {
                        label: "bottom",
                        value: "bottom",
                    },
                    {
                        label: "bottom-start",
                        value: "bottom-start",
                    },
                    {
                        label: "bottom-end",
                        value: "bottom-end",
                    },
                ],
            },
            {
                type: "switch",
                field: "splitButton",
                title: "下拉触发元素呈现为按钮组",
            },
            {
                type: "select",
                field: "typeButton",
                title: "按钮类型",
                options: [
                    {
                        label: "primary",
                        value: "primary",
                    },
                    {
                        label: "success",
                        value: "success",
                    },
                    {
                        label: "info",
                        value: "info",
                    },
                    {
                        label: "warning",
                        value: "warning",
                    },
                    {
                        label: "danger",
                        value: "danger",
                    },
                ],
            },
            {
                type: "select",
                field: "size",
                title: "尺寸",
                options: [
                    {
                        label: "medium",
                        value: "medium",
                    },
                    {
                        label: "small",
                        value: "small",
                    },
                    {
                        label: "mini",
                        value: "mini",
                    },
                ],
            },
            {
                type: "input",
                field: "customStyle",
                title: "下拉框选项自定义样式",
                value: "",
                props: {
                    type: "textarea",
                    rows: 8,
                },
            },
            {
                type: "yesStruct",
                field: "clickEvent",
                title: "点击事件",
                props: {
                    valueType: "function",
                },
            },
        ];
    },
};
