import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";
const label = "数据透视图";
const name = "yes-pivottable";

export default {
  icon: "yes-icon-table",
  label,
  name,
  mask: false,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      props: {
        dataSource:"",
        countTypeDefault:"Count",
        typeDefault:"Table",
        rowsDefault:['name'],
        colsDefault:['month'],
        valsDefault:['value']
      },
      children: [],
    };
  },
  // children: "",
  props() {
    return [
      createName(),
      dataSource(),
      {
        type: 'select', field: 'typeDefault', title: '表格类型',
        options: [
          { label: 'Table', value: 'Table' },
          { label: 'Table Heatmap', value: 'Table Heatmap' },
          { label: 'Table Col Heatmap', value: 'Table Col Heatmap' },
          { label: 'Table Row Heatmap', value: 'Table Row Heatmap' },
          { label: 'Export Table TSV', value: 'Export Table TSV' }
        ]
      },
      {
        type: 'select', field: 'countTypeDefault', title: '统计类型',
        options: [
          { label: 'Count', value: 'Count' },
          { label: 'Count Unique Values', value: 'Count Unique Values' },
          { label: 'List Unique Values', value: 'List Unique Values' },
          { label: 'Sum', value: 'Sum' },
          { label: 'Integer Sum', value: 'Integer Sum' },
          { label: 'Average', value: 'Average' },
          { label: 'Median', value: 'Median' },
          { label: 'Sample Variance', value: 'Sample Variance' },
          { label: 'Sample Standard Deviation', value: 'Sample Standard Deviation' },
          { label: 'Minimum', value: 'Minimum' },
          { label: 'Maximum', value: 'Maximum' },
          { label: 'First', value: 'First' },
          { label: 'Last', value: 'Last' },
          { label: 'First', value: 'First' },
          { label: 'Sum over Sum', value: 'Sum over Sum' },
          { label: 'Sum as Fraction of Total', value: 'Sum as Fraction of Total' },
          { label: 'Sum as Fraction of Rows', value: 'Sum as Fraction of Rows' },
          { label: 'Count as Fraction of Total', value: 'Count as Fraction of Total' },
          { label: 'Count as Fraction of Rows', value: 'Count as Fraction of Rows' },
          { label: 'Count as Fraction of Columns', value: 'Count as Fraction of Columns' }
        ]
      },
      {
        type: "yesStruct",
        field: 'rowsDefault',
        title: '行配置',
        info:"['name']",
        props: {
          type: "textarea",
          rows: 8
        }
      },
      {
        type: "yesStruct",
        field: 'colsDefault',
        title: '列配置',
        info:"['month']",
        props: {
          type: "textarea",
          rows: 8
        },
      },
      {
        type: "yesStruct",
        field: 'valsDefault',
        title: '值配置',
        info:"['value']",
        props: {
          type: "textarea",
          rows: 8
        },
      }
    ];
  },
};
