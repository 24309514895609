import { createName } from '../../utils';
import uniqueId from '@form-create/utils/lib/unique';
const label = 'iframe';
const name = 'yes-iframe';

export default {
  icon: "yes-icon-iframe",
  label,
  name,
  rule() {
    return {
      name: uniqueId(),
      type: name,
      title: label,
      props: {
        // width: '100%',
        // height: '700px'
      },
    };
  },
  props() {
    return [
      createName(),
      { type: 'input', field: 'url', title: '地址' },
      { type: 'input', field: 'width', title: '宽度' },
      { type: 'input', field: 'height', title: '高度' },
      { type: 'input', field: 'fontFamily', title: 'body字体' },
      { type: 'input', field: 'fontFamilyTime', title: 'body字体赋值延时', info: '根据性能而定' },
    ];
  },
};
