import { getComponent, getFdeParams } from '@/api/core/component';
import uniqueId from '@form-create/utils/lib/unique';
import Vue from "vue";
import yesMobileComponent from "@/components/yesMobileComponent.vue";
import yesDiv from "./rule/yesDiv.js";
const path = require('path')
import { createName, makeOptionsRule } from '../utils';
const menus = [
  {
    name: "layout",
    title: "手机组件",
    list: [yesDiv]
  }
];
async function newMenu(menu) {
  let label = menu.name
  let name = menu.code
  Vue.component(name, yesMobileComponent)
  // 配置属性
  let props = [
    createName(),
    {
      type: 'input',
      field: 'yesName',
      title: '名称',
    }
  ]
  // 属性的默认值
  let propsValue = {
    yesType: menu.code,
    yesIcon: menu.icon,
    yesName: label
  }
  let menusRes = await getFdeParams({ "com_uid": menu.uid });
  menusRes.data.map(v => {
    // 动态添加配置属性
    let p = {}
    if (v.options) {
      p = {
        type: 'select',
        field: v.code,
        title: v.name,
        options: eval(v.options)
      }
    } else {
      switch (v.type) {
        case 'Number':
          p = {
            type: "inputNumber",
            field: v.code,
            title: v.name
          }
          break;
        case 'JSON':
          p = {
            type: "yesStruct",
            field: v.code,
            title: v.name,
            props: {
              type: "textarea",
              rows: 8,
            },
          }
          break;
        case 'Array':
          p = {
            type: "yesStruct",
            field: v.code,
            title: v.name,
            props: {
              type: "textarea",
              rows: 8,
            },
          }
          break;
        case 'Event':
          p = {
            type: "yesStruct",
            field: v.code,
            title: v.name,
            info: '[{"id":"uni","method":"navigateTo","params":{"url":"/pages/index/view?app=mobile&code=index"}}]',
            props: {
              valueType: "textarea"
            }
          }
          break;
        default:
          p = { type: "input", field: v.code, title: v.name }
          break;
      }
    }
    props.push(p)
    //动态添加默认值
    if (v.value !== undefined && v.value !== '') {
      let value = v.value
      if (v.type === 'Boolean') value = value === 0 || value === '0' ? false : true
      if (v.type === 'Number') value = Number(value)
      if (v.type === 'JSON') {
        value = JSON.parse(value)
      }
      if (v.type === 'Array' || v.type === 'Event') value = eval(value)
      propsValue[v.code] = value
    } else {
      if (v.type === 'JSON') {
        propsValue[v.code] = {}
      }
      if (v.type === 'Array' || v.type === 'Event') {
        propsValue[v.code] = []
      }
    }
  })
  if (menu.is_validate === 1) {
    let validator = {
      type: "yesStruct",
      field: "validator",
      title: "数据校验",
      info: ' [{"type": "required", "message": "请输入名称"}]',
      props: {
        valueType: "textarea"
      }
    }
    props.push(validator)
    propsValue['validator'] = []
  }

  //添加布局样式
  props.push({
    type: "input",
    field: "definedCss",
    title: "布局样式",
    props: {
      type: "textarea",
      rows: 8,
    },
  })

  let m = {
    icon: menu.icon,
    label,
    name,
    drag: menu.is_drag === 1 ? true : false,
    inside: menu.is_inside === 1 ? true : false,
    mask: false,
    children: menu.children,
    rule() {
      return {
        type: name,
        name: uniqueId(),
        props: JSON.parse(JSON.stringify(propsValue)),
        children: [
        ],
      }
    },
    props() {
      return JSON.parse(JSON.stringify(props))
    }
  }
  return m
}

export default async function createMobileMenu() {
  let result = [
    {
      name: "layout",
      title: "手机组件",
      list: [yesDiv]
    }
  ]
  let menusRes = await getComponent({ type: "COMPONENT" });
  for (let i = 0; i < menusRes.data.length; i++) {
    let m = await newMenu(menusRes.data[i]);
    result[0].list.push(m);
  }
  return result;
}
