import { createName, dataSource } from '../../utils';
import uniqueId from '@form-create/utils/lib/unique';

const label = '时间线';
const name = 'yes-timeline';

export default {
  icon: 'yes-icon-timeline',
  label,
  name,
  mask: false,
  rule() {
    return {
      name: uniqueId(),
      type: name,
      title: label,
      props: {
        height: '100px',
        size: 'normal',
        dataSource:""
      },
      children: [],
    };
  },
  children: 'yes-timeline-item',
  props() {
    return [
      createName(),
      dataSource(),
      { type: 'input', field: 'showField', title: '内容字段' },
      { type: 'switch', field: 'reverse', title: '排序' },
      { type: 'switch', field: 'hideTimestamp', title: '是否隐藏时间戳' },
      {
        type: 'select',
        field: 'placement',
        title: '时间戳位置',
        options: [
          { label: '上', value: 'top' },
          { label: '下', value: 'bottom' },
        ],
      },
      {
        type: 'select',
        field: 'size',
        title: '节点尺寸',
        options: [
          { label: '默认', value: 'normal' },
          { label: '大', value: 'large' },
        ],
      },
      { type: 'input', field: 'height', title: '高度' },
      // { type: 'select', field: 'type', title: '节点类型', options: [{value: '默认', label:'primary' }, {label: '成功', value: 'success'}, {label: '警告', value: 'warning'}, {label: '消息', value: 'info'}, {label: '错误', value: 'danger'}] },
      // { type: 'input', field: 'color', title: '节点颜色' },
      // { type: 'input', field: 'icon', title: '节点图标' },
    ];
  },
};
