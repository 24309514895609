// import uniqueId from '@form-create/utils/lib/unique';
import { createName, dataSource } from '../../utils';
const label = '标记';
const name = 'yes-badge';

export default {
  icon: 'yes-icon-badge',
  label,
  name,
  rule() {
    return {
      type: name,
      // field: uniqueId(),
      title: label,
      info: '',
      props: {
        dataSource:""
      },
    };
  },
  props() {
    return [
      createName(),
      dataSource(),
      // { type: 'input', field: 'showValue', title: '显示值' },
      { type: 'inputNumber', field: 'max', title: '最大值' },
      { type: 'switch', field: 'isDot', title: '小圆点' },
      { type: 'switch', field: 'hidden', title: '隐藏值' },
      {
        type: 'select',
        field: 'type',
        title: '类型',
        options: [
          { label: 'primary', value: 'primary' },
          { label: 'success', value: 'success' },
          { label: 'warning', value: 'warning' },
          { label: 'danger', value: 'danger' },
          { label: 'info', value: 'info' },
        ],
      },
    ];
  },
};
