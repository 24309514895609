import { createName, dataSource } from "../../utils";
const label = "进度条";
const name = "yes-progress";

export default {
  icon: "yes-icon-progress",
  label,
  name,
  rule() {
    return {
      type: name,
      title: label,
      props: {
        dataSource: ""
      },
    };
  },
  props() {
    return [
      createName(),
      dataSource(),
      { type: "inputNumber", field: "percentage", title: "进度条的百分比", props: { placeholder: '请输入0-100之间的数', max: 900, min: 0 } },
      { type: "select", field: "type", title: "进度条类型", options: [{ label: "线条", value: "line" }, { label: "圆形", value: "circle" }] },
      { type: "select", field: "status", title: "进度条状态", options: [{ label: "无", value: "" }, { label: "成功", value: "success" }, { label: "异常", value: "exception" }, { label: "警告", value: "warning" }] },
      { type: "inputNumber", field: "strokeWidth", title: "进度条的宽度", props: { placeholder: '请输入数字' } },
      { type: "switch", field: "textInside", title: "进度条显示文字内置在进度条内(只在 type=line 时可用)" },
      { type: "switch", field: "showText", title: "是否显示进度条文字内容" },
      { type: "select", field: "strokeLinecap", title: "路径两端的形状", options: [{ label: "butt", value: "butt" }, { label: "square", value: "square" }, { label: "round", value: "round" }] },
      { type: "input", field: "width", title: "画布宽度(只在 type 为 circle 时可用)" },
      { type: "input", field: "fontStyle", title: "进度条字体样式", info: "font-size: 20px !important; color: #409EFF!important;" },
      { type: "input", field: "color", title: "进度条背景色(会覆盖 status 状态颜色)", info: `rgba(255, 255, 255, 0.2) 或 #FFF, 或者数组[{"color": "#f56c6c", "percentage": 20}, {"color": "#e6a23c", "percentage": 40}]` },
      { type: "input", field: "defineBackColor", title: "指定进度条底色（支持 hex 格式）", info: "rgba(255, 255, 255, 0.2) 或 #FFF" },
      { type: "input", field: "textColor", title: "指定进度条字体颜色（支持 hex 格式）", info: "rgba(255, 255, 255, 0.2) 或 #FFF" },
      { type: "yesStruct", field: "formatEvent", title: "指定进度条文字内容事件", props: { valueType: "function" }, info: "return percentage === 100 ? '满' : `${percentage}%`;" }
    ];
  },
};
