const label = '日历';
const name = 'yesCalendar';
import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";
export default {
    icon: "yes-icon-calendar",
    label,
    name,
    rule() {
        return {
            type: name,
            name: uniqueId(),
            title: label,
            props: {
                height: '800px',
                width: '100%',
                view: 'month',
                isReadOnly: false,
                useFormPopup: true,
                useDetailPopup: true,
                defaultEvents: [],
                defaultDate: '2023-05-30',
                month: { dayNames: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'] },
                week: {
                    showTimezoneCollapseButton: true,
                    timezonesCollapsed: false,
                    eventView: true,
                    taskView: true,
                    dayNames: ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
                },
                calendars: [
                    {
                        id: '0',
                        name: 'Private',
                        backgroundColor: '#9e5fff',
                        borderColor: '#9e5fff',
                        dragBackgroundColor: '#9e5fff',
                    },
                    {
                        id: '1',
                        name: 'Company',
                        backgroundColor: '#00a9ff',
                        borderColor: '#00a9ff',
                        dragBackgroundColor: '#00a9ff',
                    }
                ]
            },
        };
    },
    props() {
        return [
            createName(),
            dataSource(),
            {
                type: "input",
                field: "height",
                info: '组件高度，例： 800px',
                title: "高度",
            },
            {
                type: "input",
                field: "width",
                info: '组件宽度，例： 800px or 100%',
                title: "宽度",
            },
            {
                type: "input",
                field: "defaultDate",
                info: '设置日历组件显示的时间范围，可以是时间对象，也可以是字符串； 例: "2023-07-31", 可以使用 setDefaultDate(val) 函数, 传参初始化时间',
                title: "设置日历组件显示的时间范围",
            },
            {
                type: 'select',
                field: 'isReadOnly',
                info: '是否只读 可以使用 setReadOnly(val) 函数, 传参控制',
                title: '是否只读',
                options: [
                    { label: '是', value: true },
                    { label: '否', value: false }
                ]
            },
            {
                type: 'select',
                field: 'useFormPopup',
                title: '点击日历是否弹出默认弹窗',
                options: [
                    { label: '是', value: true },
                    { label: '否', value: false }
                ]
            },
            {
                type: 'select',
                field: 'useDetailPopup',
                title: '点击日历中的事件是否弹出默认弹窗',
                options: [
                    { label: '是', value: true },
                    { label: '否', value: false }
                ]
            },
            {
                type: 'yesStruct',
                field: 'descScript',
                title: '事件说明',
                value: '1. 事件中都包含 "calenderObject" 对象，是原生事件中的入参对象，可以自行打印看对象内容。'
                    + '\n 2. 如果关闭了 "点击日历是否弹出默认弹窗" 或 "点击日历中的事件是否弹出默认弹窗", 自行弹窗操作，需根据规范，新增调用 "onBeforeCreateEvent(eventData, customEventData)" 函数，更新调用 "onBeforeUpdateEvent(updateData, customEventData)" 函数，，入参参考对象(https://github.com/nhn/tui.calendar/blob/main/docs/en/apis/event-object.md):'
                    + `\n\n{\n  'id': '必填字符串, 唯一id',\n  'calendarId': '必填字符串, 分组事件id',\n  'title':'必填字符串，事件标题',\n  'isAllday':'必填boolean，是否是全天, true or false',\n  'start': '必填，开始时间，时间对象 or 字符串时间',\n  'end': '必填，结束时间，时间对象 or 字符串时间',\n  'location': '选填字符串, 位置',\n  'state': '必填字符串，Busy or Free',\n  'isPrivate': '必填boolean, 是否是专用、私人',\n  'attendees': '选填字符串数组, 关联人'\n}`
                    + `\n\n预留函数:\n1. 设置默认日历事件setDefaultEvents(val) \n2. 设置日历事件setFvalue(val) \n3. 设置日历组件显示的时间范围setDefaultDate(val) \n4. 设置周数据选项setWeekOptions(val)`
                    + `\n4.设置月数据选项setMonthOptions(val) \n5. 设置组件是否只读setReadOnly(val) \n6. 设置样式setThemeData(val) \n7. 设置分组日历事件setCalendars(val) `
                    + `\n\nonBeforeCreateEvent(eventData, customEventData) 函数说明: 如果是自定义弹窗，且想要细化操作字段，参考第2点的文档后，将自定义参数传入第二个入参，并将第一个入参设置为null onBeforeCreateEvent(null, customEventData)`
                    + `\nonBeforeUpdateEvent(updateData, customEventData) 函数说明: 如果是自定义弹窗，且想要细化操作字段，参考第2点的文档后，将自定义参数传入第二个入参，并将第一个入参设置为null onBeforeUpdateEvent(null, customEventData)`,
                props: {
                    valueType: 'function'
                }
            },
            {
                type: "yesStruct",
                field: "month",
                title: "日历实例的月展示",
                info: "可选项: {'name': '数字类型, 一周的开始日期。可用值为 0（星期日）到 6（星期六）', 'dayNames': '数组类型, 星期几的名称。从周日到周六应该是 7 个项目。如果未指定，则使用默认名称。 默认值为“周日”、“周一”、“周二”、“周三”、“周四”、“周五”、“周六”', 'workweek': '布尔类型, 是否排除周六和周日', 'narrowWeekend': '是否将周末的宽度缩小到一半', 'visibleWeeksCount': '要显示的周数。0 表示显示所有周'}",
                props: {
                    type: "textarea",
                    rows: 8,
                },
            },
            {
                type: "yesStruct",
                field: "week",
                title: "日历实例的周展示",
                info: "可选项: { 'name': '数字类型, 一周的开始日期。可用值为 0（星期日）到 6（星期六）', 'dayNames': '数组类型, 星期几的名称。从周日到周六应该是 7 个项目。如果未指定，则使用默认名称。 默认值为“周日”、“周一”、“周二”、“周三”、“周四”、“周五”、“周六”','workweek': '布尔类型, 是否排除周六和周日', 'narrowWeekend': '是否将周末的宽度缩小到一半', "
                    + "'visibleWeeksCount': '要显示的周数。0 表示显示所有周','showTimezoneCollapseButton': '布尔类型, 是否显示时区折叠按钮','timezonesCollapsed': '布尔类型, 是否折叠时区','hourStart': '数字类型, 一天中的开始时间。可用值为 0 到 24','hourEnd': '数字类型, 一天中的结束时间。可用值为 0 到 24。必须大于开始时间','eventView': '布尔|数组<字符串>类型, 确定要显示事件的视图。可用值为“全天”和“时间”。设置为 以禁用事件视图','taskView': '布尔|数组<字符串>类型, 确定要显示任务的视图。可用值为“里程碑”和“任务”。设置为 以禁用任务视图','collapseDuplicateEvents': '布尔|对象类型, 是否折叠重复事件。如果要过滤重复事件并根据需要选择主事件，请设置 和 。有关详细信息，请参阅指南中的选项'}",
                props: {
                    type: "textarea",
                    rows: 8,
                },
            },
            {
                type: "yesStruct",
                field: "defaultEvents",
                title: "默认数据",
                info: `数据格式：[{"title": "test","location": "1","isAllday": false,"isPrivate": false,"state": "Busy","start": {"tzOffset": null,"d": {"d": "2023-05-01T16:00:00.000Z"}},"end": {"tzOffset": null,"d": {"d": "2023-05-02T16:00:00.000Z"}}}]   可以使用 setDefaultEvents(val) 函数, 传参控制`,
                props: {
                    type: "textarea",
                    rows: 8,
                },
            },
            // {
            //     type: "yesStruct",
            //     field: "eventFilter",
            //     title: "数据过滤",
            //     info: "如果应显示事件，则返回 true。默认筛选器检查事件的属性是否为 true， 文档地址： https://github.com/nhn/tui.calendar/blob/main/docs/en/apis/options.md#weekcollapseduplicateevents",
            //     props: {
            //         type: "textarea",
            //         rows: 8,
            //     },
            // },
            {
                type: 'yesStruct',
                field: 'clickScript',
                title: '点击网格中的事件后的操作事件',
                info: '点击某一个网格中的事件后触发，若自定义弹窗自行操作',
                value: '',
                props: {
                    valueType: 'function'
                }
            },
            {
                type: 'yesStruct',
                field: 'afterRenderScript',
                title: '事件保存后，页面渲染后的操作事件',
                info: '更新或新增或删除后触发',
                value: '',
                props: {
                    valueType: 'function'
                }
            },
            {
                type: 'yesStruct',
                field: 'clickDayNameScript',
                title: '按周或按天中，点击某个日期标题的操作事件',
                info: '类型是按周或按天显示时，点击日期标题后触发',
                value: '',
                props: {
                    valueType: 'function'
                }
            },
            {
                type: 'yesStruct',
                field: 'selectDateTimeScript',
                title: '点击网格后的操作事件',
                info: '点击某一个网格后触发，若自定义弹窗自行操作',
                value: '',
                props: {
                    valueType: 'function'
                }
            },
            {
                type: 'yesStruct',
                field: 'beforeUpdateScript',
                title: '更新事件',
                info: '更新某一个网格中的事件前触发，若自定义弹窗需主动触发',
                value: '',
                props: {
                    valueType: 'function'
                }
            },
            {
                type: 'yesStruct',
                field: 'beforeDeleteScript',
                title: '删除事件',
                info: '删除某一个网格中的事件前触发，若自定义弹窗需主动触发',
                value: '',
                props: {
                    valueType: 'function'
                }
            },
            {
                type: 'yesStruct',
                field: 'beforeCreateScript',
                title: '加载前脚本',
                info: '初始化加载，在create生命周期',
                value: '',
                props: {
                    valueType: 'function'
                }
            },
            {
                type: 'yesStruct',
                field: 'afterCreateScript',
                title: '加载后脚本',
                info: '初始化加载，在mounted生命周期',
                value: '',
                props: {
                    valueType: 'function'
                }
            },
            {
                type: 'yesStruct',
                field: 'beforeCreateEventScript',
                title: '新增网格中的事件前操作事件',
                info: '点击某一个网格中后填写对应操作后点击save时触发，若自定义弹窗需主动触发',
                value: '',
                props: {
                    valueType: 'function'
                }
            }
        ];
    }
};
