import { dataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";

const label = "脚本编辑器";
const name = "yes-monacoEdit";

export default {
  icon: "yes-icon-monacoEdit",
  label,
  name,
  rule() {
    return {
      type: name,
      field: uniqueId(),
      title: label,
      props: {
        dataSource:""
      },
    };
  },
  props() {
    return [dataSource()];
  },
};
