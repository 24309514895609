import uniqueId from "@form-create/utils/lib/unique";
import { viewDataSource } from "../../utils";

const label = "表格列";
const name = "yes-table-column";

export default {
    label,
    name,
    inside: true,
    drag: true,
    dragBtn: false,
    mask: false,
    rule() {
        return {
            type: name,
            name: uniqueId(),
            title: label,
            props: { prop: uniqueId(), label: "字段",viewDataSource: "", },
            children: []
        };
    },
    props() {
        return [
            {
                type: "input",
                field: "prop",
                title: "字段名"
            },
            {
                type: "input",
                field: "label",
                title: "显示的标题"
            },
            {
                type: "select",
                title: "列的类型",
                field: "type",
                value: "",
                options: [
                    { label: "无", value: "" },
                    { label: "selection", value: "selection" },
                    { label: "index", value: "index" },
                    { label: "expand", value: "expand" }
                ]
            },
            {
                type: "yesStruct",
                field: "formatter",
                title: "对应列的格式化方法",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "index",
                title: "自定义索引",
                props: {}
            },
            viewDataSource(),
            {
                type: "input",
                field: "columnKey",
                title: "column 的筛选条件"
            },
            {
                type: "input",
                field: "width",
                title: "列的宽度(默认)"
            },
            {
                type: "input",
                field: "minWidth",
                title: "最小宽度(默认)"
            },
            {
                type: "input",
                field: "widthLg",
                title: "列的宽度(适配大屏)"
            },
            {
                type: "input",
                field: "minWidthLg",
                title: "最小宽度(适配大屏)"
            },
            {
                type: "input",
                field: "widthMd",
                title: "列的宽度(适配中屏)"
            },
            {
                type: "input",
                field: "minWidthMd",
                title: "最小宽度(适配中屏)"
            },
            {
                type: "input",
                field: "widthXs",
                title: "列的宽度(适配小屏)"
            },
            {
                type: "input",
                field: "minWidthXs",
                title: "最小宽度(适配小屏)"
            },
            {
                type: "select",
                title: "是否固定在左侧或者右侧",
                field: "fixed",
                options: [
                    { label: "无", value: false },
                    { label: "左侧", value: "left" },
                    { label: "右侧", value: "right" }
                ]
            },
            {
                type: "yesStruct",
                field: "renderHeader",
                title: "列标题 Label 区域渲染",
                props: {}
            },
            { type: "switch", field: "sortable", title: "是否可排序" },
            {
                type: "yesStruct",
                field: "sortMethod",
                title: "列的排序方法",
                props: {}
            },
            {
                type: "yesStruct",
                field: "sortBy",
                title: "列的排序依据",
                props: {}
            },
            {
                type: "yesStruct",
                field: "sortOrders",
                title: "对应列的排序方向",
                props: {}
            },
            { type: "switch", field: "resizable", title: "是否可以通过拖动改变宽度" },
            { type: "switch", field: "showOverflowTooltip", title: "是否需要 tooltip 显示" },
            {
                type: "input",
                field: "align",
                title: "文字对齐方式"
            },
            {
                type: "input",
                field: "headerAlign",
                title: "头部文字对齐方式"
            },
            {
                type: "input",
                field: "className",
                title: "自定义类名"
            },
            {
                type: "input",
                field: "labelClassName",
                title: "标题自定义类名"
            },
            {
                type: "yesStruct",
                field: "selectable",
                title: "对应列是否可以选择",
                props: {}
            },
            { type: "switch", field: "reserveSelection", title: "是否保留选中状态", info: "如需要保留选中状态, 要配置表格的rowKey" },
            {
                type: "yesStruct",
                field: "filters",
                title: "列的筛选项",
                props: {}
            },
            {
                type: "input",
                field: "filterPlacement",
                title: "过滤弹出框的定位",
                props: {}
            },
            { type: "switch", field: "filterMultiple", title: "对应列的筛选是否多选", value: true },
            {
                type: "yesStruct",
                field: "filterMethod",
                title: "列的筛选方法",
                props: {}
            },
            {
                type: "yesStruct",
                field: "filteredValue",
                title: "列的筛选值",
                props: {}
            },
            { 
                type: "switch", 
                field: "filterHandler", 
                title: "是否开启(新)列的筛选方法", 
                info: "与列的筛选方法冲突，只能使用其中一个" 
            },
        ];
    }
};
