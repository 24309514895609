import { dataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
const label = "评分";
const name = "yes-rate";

export default {
  icon: "yes-icon-rate",
  label,
  name,
  rule() {
    return {
      type: name,
      field: uniqueId(),
      title: label,
      props: {
        dataSource:""
      },
    };
  },
  props() {
    return [
      dataSource(),
      {
        type: "inputNumber",
        field: "max",
        title: "最大分值",
      },
      {
        type: "switch",
        field: "disabled",
        title: "是否只读",
      },
      {
        type: "switch",
        field: "allowHalf",
        title: "是否允许半选",
      },
      {
        type: "switch",
        field: "showText",
        title: "是否显示辅助文字",
      },
      {
        type: "yesStruct",
        field: "texts",
        title: "辅助文字数组",
        props: {},
      },
      {
        type: "switch",
        field: "showScore",
        title: "是否显示当前分数,不能同时显示分数数和文字",
      },
    ];
  },
};
