import { dataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
const label = "日期选择器";
const name = "yes-datePicker";

export default {
  icon: "yes-icon-datePicker",
  label,
  name,
  rule() {
    return {
      type: name,
      field: uniqueId(),
      title: label,
      value: "",
      props: {
        dataSource: "",
      },
    };
  },
  props() {
    return [
      dataSource(),
      {
        type: "select",
        field: "type",
        title: "类型",
        options: [
          {
            label: "year",
            value: "year",
          },
          {
            label: "month",
            value: "month",
          },
          {
            label: "date",
            value: "date",
          },
          {
            label: "daterange",
            value: "daterange",
          },
          {
            label: "dates",
            value: "dates",
          },
          {
            label: "datetime",
            value: "datetime",
          },
          {
            label: "datetimerange",
            value: "datetimerange",
          },
          {
            label: "monthrange",
            value: "monthrange",
          },
        ],
      },
      {
        type: "switch",
        field: "disabled",
        title: "是否只读",
      },
      {
        type: "switch",
        field: "clearable",
        title: "是否可清空",
      },
      {
        type: "input",
        field: "format",
        title: "绑定值时间的格式化处理(例yyyy-MM-dd,详情参考element文档)",
        info: "value-format 绑定值时间的格式化处理"
      },
      {
        type: "input",
        field: "showFormat",
        title: "显示时间的格式化处理(例yyyy-MM-dd,详情参考element文档)",
        info: "format 组件显示时间的格式化处理"
      },
      {
        type: "input",
        field: "rangeSeparator",
        title: "时间范围链接词(daterange类型时有效)",
      },
      {
        type: "input",
        field: "startText",
        title: "开始时间展示文字",
      },
      {
        type: "input",
        field: "endText",
        title: "结束时间展示文字",
      },
      {
        type: "input",
        field: "placeholder",
        title: "选择框占位符",
      },
      {
        type: "yesStruct",
        field: "changeEvent",
        title: "change事件",
        props: {
          valueType: "function",
        },
      },
      {
        type: "yesStruct",
        field: "disabledDateEvent",
        title: "设置日期禁用状态事件",
        props: {
          valueType: "function",
        },
      },
      {
        type: "select",
        field: "size",
        title: "尺寸",
        options: [
          {
            label: "large",
            value: "large",
          },
          {
            label: "small",
            value: "small",
          },
          {
            label: "mini",
            value: "mini",
          }
        ],
      },
    ];
  },
};
