import uniqueId from "@form-create/utils/lib/unique";
import { dataSource } from "../../utils";

const label = "穿梭视图";
const name = "yes-transfer-table";

export default {
    icon: "yes-icon-transfer",
    label,
    name,
    rule() {
        return {
            type: name,
            field: uniqueId(),
            title: label,
            info: "",
            props: {
                rowKey: "",
                initData: {},
                pageSize: 10,
                dataSource: "",
                showColumnKeys: [],
                clearText: "清 空",
                confirmText: "确 定",
                addText: "新 增",
                removeText: "移 除",
                title: "穿梭视图",
                leftTitle: "左侧标题",
                rightTitle: "右侧标题",
                queryInputPlaceholder: "请输入搜索内容,按回车键触发"
            }
        };
    },
    props() {
        // 表格配置
        return [
            dataSource(),
            {
                type: "input",
                field: "app",
                title: "应用编号"
            },
            {
                type: "input",
                field: "view",
                title: "视图编号"
            },
            {
                type: "input",
                field: "title",
                title: "弹窗标题"
            },
            {
                type: "input",
                field: "leftTitle",
                title: "左侧标题"
            },
            {
                type: "input",
                field: "rightTitle",
                title: "右侧标题"
            },
            {
                type: "input",
                field: "addText",
                title: "新增按钮文本"
            },
            {
                type: "input",
                field: "removeText",
                title: "移除按钮文本"
            },
            {
                type: "input",
                field: "viewLabel",
                title: "输入框展示字段"
            },
            {
                type: "input",
                field: "viewValue",
                title: "输入框绑定字段"
            },
            {
                type: "input",
                field: "search",
                title: "搜索框绑定字段"
            },
            {
                type: "input",
                field: "clearText",
                title: "清空按钮文本"
            },
            {
                type: "input",
                field: "confirmText",
                title: "确定按钮文本"
            },
            {
                type: "input",
                field: "rowKey",
                title: "行数据的Key[rowKey]",
                info: "根据row[rowKey]的方式来指定每行数据的key, 若放空且每行数据存在uid, 用uid来作为rowKey, 否则rowKey=undefined."
            },
            {
                type: "yesStruct",
                field: "showColumnKeys",
                title: "显示字段",
                value: [],
                info: "[{'key': 'name','width': '200'}",
                props: {
                    type: "textarea",
                    rows: 8
                }
            },
            // {
            //     type: "switch",
            //     field: "multiSelect",
            //     title: "是否多选"
            // },
            {
                type: "input",
                field: "maxHeight",
                title: "表格最大高度"
            },
            {
                type: "yesStruct",
                field: "initData",
                title: "默认参数",
                props: {
                    type: "textarea",
                    rows: 8
                }
            },
            {
                type: "switch",
                field: "disabled",
                title: "是否禁用"
            },
            {
                type: "input",
                field: "pageSize",
                title: "每页条数"
            },
            {
                type: "input",
                field: "queryInputPlaceholder",
                title: "搜索输入框占位符"
            },
            // {
            //   type: 'input',
            //   field: 'inputWidth',
            //   title: '搜索框默认宽度(px)',
            //   },{
            //     type: 'input',
            //     field: 'columnWidth',
            //     title: '表格列默认宽度(px)',
            //   },{
            //     type: 'input',
            //     field: 'tableWidth',
            //     title: '表格默认宽度(px/%)',
            //   },
            {
                type: "switch",
                field: "isEnterQuery",
                title: "回车时是否查询数据"
            },
            {
                type: "yesStruct",
                field: "inputClick",
                title: "点击输入框事件",
                info: "参数(vm, api, dao, utils, selectRow, fvalue, inputName)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "enterClick",
                title: "回车事件",
                info: "参数(searchData, api, dao, utils)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "submitMethodEvent",
                title: "点击确定事件",
                info: "参数(row, api, dao, utils)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "queryViewAfterEvent",
                title: "查询视图后执行的脚本",
                info: "参数(vm, api, dao, utils, tableData)",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "clearMethodEvent",
                title: "点击清空事件",
                info: "参数(row, api, dao, utils)",
                props: {
                    valueType: "function"
                }
            }
        ];
    }
};
