import { createName, dataSource } from "../../utils";
const label = "文本";
const name = "yes-text";

export default {
    icon: "yes-icon-text",
    label,
    name,
    rule() {
        return {
            type: name,
            // field: uniqueId(),
            title: label,
            info: "",
            props: {
                textValue: "",
                dataSource: "",
                preventTime: 2000,
                preventClick: false,
                showPreventTip: false,
                preventClickTip: "执行操作中, 请勿重复点击"
            }
        };
    },
    props() {
        return [
            createName(),
            dataSource(),
            {
                type: "input",
                field: "textValue",
                title: "文本",
                props: {
                    type: "textarea",
                    rows: 8
                }
            },
            {
                type: "input",
                field: "textStyle",
                title: "样式",
                value: "",
                props: {
                    type: "textarea",
                    rows: 8
                }
            },
            {
                type: "switch",
                field: "preventClick",
                title: "是否阻止重复点击"
            },
            {
                type: "inputNumber",
                field: "preventTime",
                title: "阻止重复点击时间"
            },
            {
                type: "switch",
                field: "showPreventTip",
                title: "是否弹出重复点击提示"
            },
            {
                type: "input",
                field: "preventClickTip",
                title: "重复点击提示内容"
            },
            {
                type: "yesStruct",
                field: "clickEvent",
                title: "点击事件",
                props: {
                    valueType: "function"
                }
            }
        ];
    }
};
