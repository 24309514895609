import { dataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
const label = "时间选择器";
const name = "yes-timepicker";

export default {
  icon: "yes-icon-time",
  label,
  name,
  rule() {
    return {
      type: name,
      field: uniqueId(),
      title: label,
      props: {
        dataSource:""
      },
    };
  },
  props() {
    return [
      dataSource(),
      {
        type: "switch",
        field: "range",
        title: "是否时间范围",
      },
      {
        type: "switch",
        field: "control",
        title: "是否箭头控制",
      },
      {
        type: "input",
        field: "rangeSeparator",
        title: "时间范围链接词",
      },
      {
        type: "input",
        field: "defaultValue",
        info: "02:00:00",
        title: "默认时间",
      },
      {
        type: "input",
        field: "startText",
        info: "02:00:00",
        title: "默认开始时间",
      },
      {
        type: "input",
        field: "endText",
        info: "04:00:00",
        title: "默认结束时间",
      },
      {
        type: "input",
        field: "placeholder",
        title: "选择框占位符",
      },
      {
        type: "switch",
        field: "disabled",
        title: "是否只读",
      },
      {
        type: "yesStruct",
        field: "changeEvent",
        title: "change事件",
        props: {
          valueType: "function",
        },
      },
    ];
  },
};
