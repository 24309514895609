import { dataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
import { getOptions } from "../../utils/classCss";
const label = "输入框";
const name = "yes-input";

export default {
    icon: "yes-icon-input",
    label,
    name,
    rule() {
        return {
            field: uniqueId(),
            title: label,
            type: name,
            info: "",
            props: {
                modelValue: "",
                dataSource: ""
            }
        };
    },
    props() {
        return [
            dataSource(),
            {
                type: "select",
                field: "type",
                title: "类型",
                options: [
                    {
                        label: "text",
                        value: "text"
                    },
                    {
                        label: "textarea",
                        value: "textarea"
                    },
                    {
                        label: "number",
                        value: "number"
                    },
                    {
                        label: "password",
                        value: "password"
                    }
                ]
            },
            {
                type: "input",
                field: "modelValue",
                title: "默认值"
            },
            {
                type: "inputNumber",
                field: "maxlength",
                title: "最大输入长度"
            },
            {
                type: "inputNumber",
                field: "minlength",
                title: "最小输入长度"
            },
            {
                type: "switch",
                field: "showWordLimit",
                title: "是否显示输入字数统计"
            },
            {
                type: "input",
                field: "placeholder",
                title: "输入框占位文本"
            },
            {
                type: "switch",
                field: "clearable",
                title: "是否可清空"
            },
            {
                type: "switch",
                field: "showPassword",
                title: "是否显示切换密码图标"
            },
            {
                type: "switch",
                field: "disabled",
                title: "禁用"
            },
            {
                type: "select",
                field: "size",
                title: "尺寸",
                options: [
                    {
                        label: "medium",
                        value: "medium"
                    },
                    {
                        label: "small",
                        value: "small"
                    },
                    {
                        label: "mini",
                        value: "mini"
                    }
                ]
            },
            {
                type: "input",
                field: "prefixIcon",
                title: "输入框头部图标"
            },
            {
                type: "input",
                field: "suffixIcon",
                title: "输入框尾部图标"
            },
            {
                type: "inputNumber",
                field: "rows",
                info: '只对 type="textarea" 有效',
                title: "输入框行数"
            },
            {
                type: "select",
                field: "autocomplete",
                title: "自动补全",
                options: [
                    {
                        label: "on",
                        value: "on"
                    },
                    {
                        label: "off",
                        value: "off"
                    },
                    {
                        label: "new-password",
                        value: "new-password"
                    }
                ]
            },
            {
                type: "switch",
                field: "readonly",
                title: "是否只读"
            },
            {
                type: "select",
                field: "resize",
                title: "控制是否能被用户缩放",
                options: [
                    {
                        label: "none",
                        value: "none"
                    },
                    {
                        label: "both",
                        value: "both"
                    },
                    {
                        label: "horizontal",
                        value: "horizontal"
                    },
                    {
                        label: "vertical",
                        value: "vertical"
                    }
                ]
            },
            {
                type: "switch",
                field: "autofocus",
                title: "自动获取焦点"
            },
            {
                type: "select",
                field: "titleCss",
                title: "标题样式",
                options: getOptions()
            },
            {
                type: "select",
                field: "contentCss",
                title: "内容样式",
                options: getOptions()
            },
            {
                type: "input",
                field: "definedCss",
                title: "自定义样式",
                props: {
                    type: "textarea",
                    rows: 8
                }
            },
            {
                type: "yesStruct",
                field: "enterEventScript",
                title: "键盘回车事件",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "inputEventScript",
                title: "输入事件",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "focusEvent",
                title: "获得焦点事件",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "missFocusEvent",
                title: "失去焦点事件",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "changeEvent",
                title: "change事件",
                props: {
                    valueType: "function"
                }
            }
        ];
    }
};
