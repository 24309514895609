import { createName, dataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";

const label = "webdatarocks";
const name = "yes-web-datarocks";

export default {
    icon: "yes-icon-workbook1",
    label,
    name,
    mask: false,
    rule() {
        return {
            name: uniqueId(),
            type: name,
            title: label,
            props: {
                dataSource: "",
                dataFields: {
                    field1: {
                        type: "number",
                    },
                    group1: {
                        type: "level",
                    },
                },
                toolbarShow: false,
            },
        };
    },
    props() {
        return [
            createName(),
            dataSource(),
            {
                type: "yesStruct",
                field: "dataFields",
                title: "数据字段配置",
                info: "字段类型参考https://www.webdatarocks.com/doc/mapping-object-for-json/",
                props: {
                    type: "textarea",
                    rows: 8,
                },
                value: {
                    field1: {
                        type: "number",
                    },
                    group1: {
                        type: "level",
                    },
                },
            },
            {
                type: "yesStruct",
                field: "allConfig",
                title: "内容配置",
                props: {
                    type: "textarea",
                    rows: 8,
                },
            },
            {
                type: "yesStruct",
                field: "customizeCellScript",
                title: "自定义单元格配置",
                info: `参数(vm,api,dao,utils,cellBuilder,cellData)`,
                props: {
                    valueType: "function",
                },
            },
            {
                type: "select",
                field: "toolbarShow",
                title: "是否显示工具项",
                options: [
                    { label: "显示", value: true },
                    { label: "隐藏", value: false },
                ],
                value: false,
            },
            {
                type: "yesStruct",
                field: "clickEvent",
                title: "单击事件",
                info: `参数(row,api,dao, utils, item),通过utils.exec("id","getConponent") 获取组件对象,getConfig和setConfig操作组件配置`,
                props: {
                    valueType: "function",
                },
            },
            {
                type: "yesStruct",
                field: "doubleClickEvent",
                title: "双击事件",
                info: `参数(row,api,dao, utils, item),通过utils.exec("id","getConponent") 获取组件对象,getConfig和setConfig操作组件配置`,
                props: {
                    valueType: "function",
                },
            },
        ];
    },
};
