import { dataSource, viewDataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
const label = "穿梭框";
const name = "yes-transfer";

export default {
  icon: "yes-icon-transfer",
  label,
  name,
  rule() {
    return {
      type: name,
      field: uniqueId(),
      title: label,
      props: {
        dataSource:"",
        viewDataSource: ""
      },
    };
  },
  props() {
    return [
      dataSource(),
      viewDataSource(),
      {
        type: "yesStruct",
        field: "left_default_checked",
        title: "左侧列表默认勾选项key 数组",
        props: {},
      },
      {
        type: "yesStruct",
        field: "right_default_checked",
        title: "右侧列表默认勾选项key 数组",
        props: {},
      },
      {
        type: "yesStruct",
        field: "buttonText",
        title: "自定义按钮文案数组",
        props: {},
      },
      {
        type: "yesStruct",
        field: "titles",
        title: "自定义列表标题数组",
        props: {},
      },
      {
        type: "input",
        field: "akey",
        title: "数据源字段别名key",
      },
      {
        type: "input",
        field: "label",
        title: "数据源字段别名label",
      },
    ];
  },
};
