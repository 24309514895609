import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";
const label = "文本链接";
const name = "yes-link";

export default {
  icon: "yes-icon-link",
  label,
  name,
  mask: false,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      props: {
        // target: "_blank",
        type: "default",
        dataSource:""
      },
      children: [],
    };
  },
  children: "yes-text",
  props() {
    return [
      createName(),
      dataSource(),
      // { type: 'input', field: 'href', title: '链接' },
      { type: 'input', field: 'icon', title: '图标' },
      // { type: 'select', field: 'target', title: '目标', options: [{ label: '新窗新页面', value: '_blank' }, { label: '同窗新页面', value: '_self' }] },
      { type: 'select', field: 'type', title: '类型', options: [{label: '默认', value: 'default'}, {label: 'primary', value: 'primary'}, {label: 'success', value: 'success'}, {label: 'warning', value: 'warning'}, {label: 'danger', value: 'danger'}, {label: 'info', value: 'info'}] },
      { type: 'switch', field: 'disabled', title: '是否禁用' },
      { type: 'switch', field: 'underline', title: '是否有下划线' },
    ];
  },
};
