import { dataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
const label = "地区选择";
const name = "yes-area-select";

export default {
  icon: "yes-icon-cascader",
  label,
  name,
  rule() {
    return {
      type: name,
      title: label,
      field: uniqueId(),
      props: {
        showLevels: true,
        dataSource:"",
        level: 3
      },
    };
  },
  props() {
    return [
      dataSource(),
      {
        type: "input",
        field: "optionSource",
        title: "绑定数据",
      },
      {
        type: "select",
        field: "level",
        title: "显示级数",
        options: [
          {
            label: "省",
            value: 1,
          },
          {
            label: "省/市",
            value: 2,
          },
          {
            label: "省/市/区",
            value: 3,
          },
          {
            label: "省/市/区/街道",
            value: 4,
          }
        ],
      },
      {
        type: "switch",
        field: "disabled",
        title: "是否只读",
      },
      {
        type: "select",
        field: "size",
        title: "尺寸",
        options: [
          {
            label: "medium",
            value: "medium",
          },
          {
            label: "small",
            value: "small",
          },
          {
            label: "mini",
            value: "mini",
          },
        ],
      },
      {
        type: "switch",
        field: "clearable",
        title: "是否可清空",
      },
      {
        type: "yesStruct",
        field: "changeEvent",
        title: "change事件",
        props: {
          valueType: "function",
        },
      },
    ];
  },
};
