import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";
const label = "HTML";
const name = "yesHtml";

export default {
  icon: "yes-icon-html",
  label,
  name,
  mask: false,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      props: {
        dataSource:"",
        htmlDiv:""
      },
      children: [],
    };
  },
  // children: "",
  props() {
    return [
      createName(),
      dataSource(),
      {
        type: "input",
        field: "htmlDiv",
        title: "自定义标签",
        value:'',
        props: {
          type: "textarea",
          rows: 8,
        },
      },
    ];
  },
};
