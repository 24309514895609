import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";
const label = "二维码";
const name = "yes-qr-code";

export default {
    icon: "yes-icon-icon",
    label,
    name,
    mask: false,
    rule() {
        return {
            type: name,
            name: uniqueId(),
            props: {
                dataSource: ""
            },
            children: [],
        };
    },
    // children: "",
    props() {
        return [
            createName(),
            dataSource(),
            { type: 'input', field: 'width', title: '二维码宽高', info: '数字类型, 例输入: 200' },
            { type: 'input', field: 'margin', title: '二维码留白边距', info: '数字类型, 例输入: 5' },
            { type: 'input', field: 'qrCodeValue', title: '二维码内容', info: '字符类型, 例输入: Anything' },
            { type: 'input', field: 'light', title: '背景颜色', info: 'RGBA, 例输入: #000000FF or #000' },
            { type: 'input', field: 'dark', title: '前景颜色', info: 'RGBA, 例输入: #FFFFFFFF or #FFF' },
        ];
    },
};
