import { dataSource, viewDataSource, makeOptionsRule } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
const label = "选择器";
const name = "yes-select";

export default {
    icon: "yes-icon-select",
    name,
    label,
    rule() {
        return {
            type: name,
            field: uniqueId(),
            title: label,
            props: {
                dataSource: "",
                defaultOptios: [],
                viewDataSource: "",
                optionWidth: "100% !important"
            }
        };
    },
    props() {
        return [
            dataSource(),
            viewDataSource(),
            {
                type: "input",
                field: "optionWidth",
                title: "选项样式宽度",
                info: "默认: 100% !important"
            },
            {
                type: "input",
                field: "itemKey",
                title: "选择器键字段(:key)"
            },
            {
                type: "input",
                field: "itemLable",
                title: "选择器显示字段(:label)"
            },
            {
                type: "input",
                field: "itemVal",
                title: "选择器绑定值(:value)"
            },
            makeOptionsRule([
                { seq: 0, field: "defaultOptios", defaultShow: true },
                { seq: 1, field: "defaultOptios" }
            ]),
            {
                type: "select",
                field: "size",
                title: "尺寸",
                options: [
                    {
                        label: "medium",
                        value: "medium"
                    },
                    {
                        label: "small",
                        value: "small"
                    },
                    {
                        label: "mini",
                        value: "mini"
                    }
                ]
            },
            {
                type: "switch",
                field: "disabled",
                title: "是否只读"
            },
            {
                type: "switch",
                field: "clearable",
                title: "是否可清空"
            },
            {
                type: "switch",
                field: "filterable",
                title: "是否可搜索匹配"
            },
            {
                type: "switch",
                field: "multiple",
                title: "是否可多选（开启后绑定值为数组类型）"
            },
            {
                type: "input",
                field: "placeholder",
                title: "选择器占位符"
            },
            {
                type: "yesStruct",
                field: "changeEvent",
                title: "change事件",
                props: {
                    valueType: "function"
                }
            },
            {
                type: "yesStruct",
                field: "defaultValues",
                title: "默认值",
                info: `是个数组 ["Value1"]`,
                props: {
                    valueType: "function"
                }
            }
        ];
    }
};
