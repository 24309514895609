import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";
const label = "一维码";
const name = "yes-bar-code";

export default {
    icon: "yes-icon-icon",
    label,
    name,
    mask: false,
    rule() {
        return {
            type: name,
            name: uniqueId(),
            props: {
                dataSource: ""
            },
            children: [],
        };
    },
    // children: "",
    props() {
        return [
            createName(),
            dataSource(),
            {
                type: 'select', field: 'barCodeType', title: '类型', info: "文档：https://github.com/lindell/JsBarcode/wiki#barcodes",
                options: [
                    { label: '默认(CODE128)', value: 'CODE128' }, { label: 'CODE128A', value: 'CODE128A' }, { label: 'CODE128B', value: 'CODE128B' }, { label: 'CODE128C', value: 'CODE128C' },
                    { label: 'EAN13', value: 'EAN13' }, { label: 'UPC', value: 'UPC' }, { label: 'EAN8', value: 'EAN8' }, { label: 'EAN5', value: 'EAN5' }, { label: 'EAN2', value: 'EAN2' },
                    { label: 'CODE39', value: 'CODE39' },
                    { label: 'ITF14', value: 'ITF14' },
                    { label: 'MSI', value: 'MSI' }, { label: 'MSI10', value: 'MSI10' }, { label: 'MSI11', value: 'MSI11' }, { label: 'MSI1010', value: 'MSI1010' }, { label: 'MSI1110', value: 'MSI1110' },
                    { label: 'pharmacode', value: 'pharmacode' },
                    { label: 'codabar', value: 'codabar' },
                ], control: [
                    {
                        value: 'CODE39',
                        rule: [{
                            type: 'select', field: 'mod43', title: '是否启用 Mod43 checksum',
                            options: [{ label: '启动', value: true }, { label: '禁用', value: false }]
                        }]
                    }, {
                        value: 'EAN13',
                        rule: [
                            {
                                type: 'select', field: 'flat', title: '是否跳出防护栏', info: "内容超出图形边界",
                                options: [{ label: '启动', value: true }, { label: '禁用', value: false }]
                            },
                            { type: 'input', field: 'lastChar', title: '最后一个字符', info: "EAN-13有时在条形码后面印有一个字符, 最常见的是>字符" }
                        ]
                    }, {
                        value: 'EAN8',
                        rule: [{
                            type: 'select', field: 'mod43', title: '启动 mod43',
                            options: [{ label: '启动', value: true }, { label: '禁用', value: false }]
                        }]
                    },
                ]
            },
            { type: 'input', field: 'width', title: '线宽', info: '线条宽度，数字类型, 例输入: 2' },
            { type: 'input', field: 'height', title: '高度', info: '条形码高度，数字类型, 例输入: 100' },
            { type: 'input', field: 'barCodeValue', title: '一维码内容' },
            {
                type: 'select', field: 'displayValue', title: '是否显示一维码内容', info: '是否显示图形下面的内容',
                options: [{ label: '显示', value: true }, { label: '隐藏', value: false }]
            },
            {
                type: 'select', field: 'fontOptions', title: '下方内容字体粗细与斜体',
                options: [{ label: '默认', value: '' }, { label: '粗体', value: 'bold' },
                { label: '斜体', value: 'italic' }, { label: '粗斜', value: 'bold italic' }]
            },
            { type: 'input', field: 'font', title: '下方内容字体样式', info: 'font-family, 例输入: fantasy' },
            {
                type: 'select', field: 'textAlign', title: '下方内容水平对齐位置',
                options: [{ label: '居中', value: 'center' }, { label: '靠左', value: 'left' }, { label: '靠右', value: 'right' }]
            },
            {
                type: 'select', field: 'textPosition', title: '下方内容垂直对齐位置',
                options: [{ label: '居下', value: 'bottom' }, { label: '居上', value: 'top' }]
            },
            { type: 'input', field: 'textMargin', title: '下方内容与一维码的间距', info: '数字类型, 例输入: 25' },
            { type: 'input', field: 'fontSize', title: '下方内容字体大小', info: '数字类型, 例输入: 40' },
            { type: 'input', field: 'background', title: '背景颜色', info: '字符类型, 例输入: #FFF' },
            { type: 'input', field: 'lineColor', title: '线条颜色', info: '字符类型, 例输入: #000' },
            { type: 'input', field: 'margin', title: '一维码边距', info: '数字类型, 例输入: 10' }
        ];
    },
};
