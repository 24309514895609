import uniqueId from "@form-create/utils/lib/unique";
import { createName } from "../../utils";
const label = "结果";
const name = "yes-result";

export default {
  icon: "yes-icon-result",
  label,
  name,
  mask: false,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      props: {},
      children: [],
    };
  },
  props() {
    return [
      createName(),
      { type: "input", field: "title", title: "标题"},
      { type: "input", field: "subTitle", title: "二级标题" },
      { type: "select", field: "icon", title: "图标", options: [{ label: "成功", value: "success" }, { label: "警告", value: "warning" }, { label: "消息", value: "info" }, { label: "失败", value: "error" }]  },
      { type: "switch", field: "showButton", title: "是否启用按钮" },
      { type: "input", field: "buttonText", title: "按钮名称", props: { placeholder: "若启用按钮, 请输入按钮文字" } },
      { type: "input", field: "clickEvent", title: "按钮事件", props: { placeholder: "若启用按钮, 请添加按钮事件" , type: 'textarea', rows: 8 } },
    ];
  },
};
