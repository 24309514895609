import { createName, dataSource } from '../../utils';
import uniqueId from '@form-create/utils/lib/unique';

const label = 'webHighchart';
const name = 'yes-webdata-highchart';

export default {
  icon: 'yes-icon-lineChart',
  label,
  name,
  mask: false,
  rule() {
    return {
      name: uniqueId(),
      type: name,
      title: label,
      props: {
        rockId:"",
        chartType:"column"
      },
    };
  },
  props() {
    return [
      createName(),
      {
        type: "input",
        field: "rockId",
        title: "关联webdatarock的id"
      },
      {
        type: 'select', field: 'chartType', title: '图形类型',
        options: [
          // { label: 'arearange', value: 'arearange' },
          { label: 'areaspline', value: 'areaspline' },
          // { label: 'areasplinerange', value: 'areasplinerange' },
          { label: 'area', value: 'area' },
          { label: 'bar', value: 'bar' },
          { label: 'column', value: 'column' },
          // { label: 'bubble', value: 'bubble' },
          // { label: 'columnrange', value: 'columnrange' },
          // { label: 'errorbar', value: 'errorbar' },
          { label: 'line', value: 'line' },
          // { label: 'funnel', value: 'funnel' },
          { label: 'pie', value: 'pie' },
          // { label: 'polygon', value: 'polygon' },
          // { label: 'pyramid', value: 'pyramid' },
          { label: 'scatter', value: 'scatter' },
          { label: 'spline', value: 'spline' }
          // { label: 'waterfall', value: 'waterfall' }
        ],
        value: 'column'
      }
    ]
  },
};
