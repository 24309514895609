const label = '工作薄';
const name = 'yesWorkbook';
import uniqueId from "@form-create/utils/lib/unique";
import { createName } from "../../utils";
export default {
  icon: "yes-icon-workbook",
  label,
  name,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      title: label,
      props: {
      },
    };
  },
    props() {
        return [
          createName(),
          {
            type: "input",
            field: "showLabel",
            title: "标签",
          },
            {
              type: "yesWorkbookEdit",
              field: "defaultJson",
              title: "格式",
              value: []
            },
            {
              type:'yesStruct',
              field:'beforeCreateScript',
              title: '加载前脚本',
              value:'',
              props: {
                  valueType : 'function'
              }
          },
          {
              type:'yesStruct',
              field:'afterCreateScript',
              title: '加载后脚本',
              value:'',
              props: {
                  valueType : 'function'
              }
          },
        ];
    }
};
