import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";
const label = "雷达图";
const name = "yes-radar";

export default {
  icon: "yes-icon-radar",
  label,
  name,
  mask: false,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      props: {
        showTooltip: true,
        canSaveImage: true,
        dataSource:""
      },
    };
  },
  props() {
    return [
      createName(),
      dataSource(),
      { type: "input", field: "nameField", title: "名称字段" },
      { type: "input", field: "typeField", title: "属性字段" },
      { type: "input", field: "valueField", title: "值字段" },
      { type: "input", field: "title", title: "标题" },
      { type: "input", field: "height", title: "高度(默认100%)" },
      { type: "input", field: "width", title: "宽度(默认100%)" },
      { type: "input", field: "marginTop", title: "顶部距离（百分比默认10%）" },
      { type: "input", field: "marginLeft", title: "左距离（百分比默认3%）" },
      { type: "input", field: "marginRight", title: "右距离（百分比默认3%）" },
      {
        type: "input",
        field: "marginBottom",
        title: "底部距离（百分比默认3%）",
      },
      { type: "switch", field: "showTooltip", title: "是否显示提示框" },
      { type: "switch", field: "canSaveImage", title: "能否保存图片" },
    ];
  },
};
