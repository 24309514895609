import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";
const label = "播放器";
const name = "yes-flv";

export default {
  icon: "yes-icon-video",
  label,
  name,
  mask: false,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      props: {
        dataSource: "",
        isControls: true
      },
      children: [],
    };
  },
  // children: "",
  props() {
    return [
      createName(),
      dataSource(),
      { type: 'input', field: 'typeDefault', title: '文件类型' },
      {
        type: 'select', field: 'isControls', title: '是否显示控制界面', info: '暂停 播放 等操作按钮',
        options: [{ label: '是', value: true }, { label: '否', value: false }]
      },
    ];
  },
};
