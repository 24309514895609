import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";
const label = "markdown";
const name = "yes-markdown";

export default {
    icon: "yes-icon-table",
    label,
    name,
    mask: false,
    rule() {
        return {
            type: name,
            name: uniqueId(),
            props: {
                initValue: "",
                dataSource: ""
            },
            children: []
        };
    },
    // children: "",
    props() {
        return [createName(), dataSource(), { type: "input", field: "initValue", title: "初始值" }];
    }
};
