import uniqueId from "@form-create/utils/lib/unique";
import { createName, dataSource } from "../../utils";
const label = "仪表盘";
const name = "yesGaugeChart";

export default {
    icon: "yes-icon-gaugeChart",
    label,
    name,
    mask: false,
    rule() {
        return {
            type: name,
            name: uniqueId(),
            props: {
                width: '100%',
                height: '100%',
                options: defaultOption
            },
        };
    },
    props() {
        return [
            createName(),
            { type: "input", field: "height", title: "高度(默认100%)" },
            { type: "input", field: "width", title: "宽度(默认100%)" },
            {
                type: "yesStruct",
                field: "options",
                title: "仪表盘配置",
                info: '示例文档: https://echarts.apache.org/examples/zh/editor.html?c=gauge-stage&random=zam1adxmxb 在yesGaugeChart.vue有配置说明'
            }
        ];
    },
};

const defaultOption = {
    series: [
        {
            type: 'gauge',
            axisLine: {
                lineStyle: {
                    width: 30,
                    color: [
                        [0.3, '#67e0e3'],
                        [0.7, '#37a2da'],
                        [1, '#fd666d']
                    ]
                }
            },
            pointer: {
                itemStyle: {
                    color: 'auto'
                }
            },
            axisTick: {
                distance: -30,
                length: 8,
                lineStyle: {
                    color: '#fff',
                    width: 2
                }
            },
            splitLine: {
                distance: -30,
                length: 30,
                lineStyle: {
                    color: '#fff',
                    width: 4
                }
            },
            axisLabel: {
                color: 'inherit',
                distance: 40,
                fontSize: 20
            },
            detail: {
                valueAnimation: true,
                formatter: '{value} km/h',
                color: 'inherit'
            },
            data: [
                {
                    value: 70
                }
            ]
        }
    ]
};