import uniqueId from '@form-create/utils/lib/unique';
import { createName } from '../../utils';

const label = '标签';
const name = 'yes-tabs-pane';

export default {
  label,
  name,
  inside: true,
  drag: true,
  dragBtn: false,
  mask: false,
  rule() {
    const uid = uniqueId();
    return {
      name: uid,
      type: 'el-tab-pane',
      props: { label: '新标签页', name: uid, disabled: false },
      children: [],
    };
  },
  props() {
    return [
      createName(),
      { type: 'input', field: 'label', title: '标题(label)' },
      { type: 'input', field: 'name', title: '绑定值(name)' },
      { type: 'switch', field: 'closable', title: '标签是否可关闭' },
      { type: 'switch', field: 'disabled', title: '是否禁用' },
      { type: 'switch', field: 'lazy', title: '标签是否延迟渲染'},
    ];
  },
};
