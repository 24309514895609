import uniqueId from '@form-create/utils/lib/unique';
const name = 'yes-collapse-item';

export default {
    name,
    drag: true,
    dragBtn: false,
    inside: true,
    mask: false,
    rule() {
        const id = uniqueId();
        return {
            type: "yes-collapse-item",
            name: id,
            props: {
                name: id,
                title: '新标题',
                disabled: false,
                height: '48px',
                padding: '',
                fontSize: '13px',
                fontWeight: '500',
                fontFamily: '',
                paddingLeft: '15px',
            },
            children: []
        };
    },
    props() {
        return [
            { type: 'input', field: 'name', title: '唯一标志符' },
            { type: 'input', field: 'title', title: '面板标题' },
            { type: 'switch', field: 'disabled', title: '是否禁用' },
            { type: 'input', field: 'height', title: '面板高度', info: 'eg: 25px' },
            { type: 'input', field: 'fontSize', title: '标题字体大小', info: 'fontSize' },
            { type: 'input', field: 'fontWeight', title: '标题字体宽度', info: 'fontWeight' },
            { type: 'input', field: 'fontFamily', title: '标题字体样式', info: 'fontFamily' },
            { type: 'input', field: 'paddingLeft', title: '标题左内间距', info: 'paddingLeft' },
            { type: 'input', field: 'padding', title: '标题内间距', info: 'padding, eg: 0 0 0 10px ; 优先级比paddingLeft高.' }
        ];
    }
};
