export default function form() {
    return [
        {
            type: "input",
            field: "pageTitle",
            title: "页面标题",
            value: "",
        },
        {
            type: "yesStruct",
            field: "pageData",
            title: "初始数据",
            props: {
                valueType: "textarea",
            },
        },
        {
            type: "yesStruct",
            field: "onLoad",
            title: "页面加载事件(onLoad)",
            value: "",
            props: {
                valueType: "textarea",
            },
        },
        {
            type: "yesStruct",
            field: "onShow",
            title: "页面显示事件(onShow)",
            value: "",
            props: {
                valueType: "textarea",
            },
        },
        {
            type: "yesStruct",
            field: "onHide",
            title: "页面隐藏事件(onHide)",
            value: "",
            props: {
                valueType: "textarea",
            },
        },
        {
            type: "yesStruct",
            field: "onPullDownRefresh",
            title: "下拉刷新事件(onPullDownRefresh)",
            value: "",
            props: {
                valueType: "textarea",
            },
        },
        {
            type: "yesStruct",
            field: "onReachBottom",
            title: "页面滚动到底部事件(onReachBottom)",
            value: "",
            props: {
                valueType: "textarea",
            },
        },
        {
            type: "yesStruct",
            field: "onPageScroll",
            title: "页面滚动事件(onPageScroll)",
            value: "",
            info: "不要写交互复杂的js, 比如频繁修改页面. @{scrollTop} 页面在垂直方向已滚动的距离（单位px）",
            props: {
                valueType: "textarea",
            },
        },
        {
            type: "yesStruct",
            field: "share",
            title: "页面分享",
            info: '{"title": "title","path": "/pages/index/view?app=mobile&code=index",imageUrl: "pic"}',
            props: {
                valueType: "textarea",
            },
        },
    ];
}
