// import uniqueId from '@form-create/utils/lib/unique';
const label = '标签';
const name = 'yes-tag';

export default {
  icon: 'yes-icon-tag',
  label,
  name,
  rule() {
    return {
      type: name,
      // field: uniqueId(),
      title: label,
      info: '',
      props: {
        values:'标签'
      },
    };
  },
  props() {
    return [{
      type:'input',
      field:'values',
      title: '值',
    },{
        type: 'select',
        field: 'type',
        title: '类型',
        options: [{
          label: 'primary',
          value: 'primary'
        },{
          label: 'success',
          value: 'success'
        }, {
          label: 'warning',
          value: 'warning'
        }, {
          label: 'info',
          value: 'info'
        }, {
          label: 'danger',
          value: 'danger'
        }]
      }, {
        type: 'switch',
        field: 'closable',
        title: '是否可关闭',
        value: false
      }, {
        type: 'switch',
        field: 'disableTransitions',
        title: '是否禁用渐变动画',
        value: false
      }, {
        type: 'switch',
        field: 'hit',
        title: '是否有边框描边',
        value: false
      }, {
        type: 'input',
        field: 'color',
        info: '输入#开头的16进制颜色编码',
        // props:{
        //   placeholder:"输入#开头的16进制颜色编码"
        // },
        title: '背景色'
      }, {
        type: 'select',
        field: 'size',
        title: '尺寸',
        options: [{
          label: 'medium',
          value: 'medium'
        }, {
          label: 'small',
          value: 'small'
        }, {
          label: 'mini',
          value: 'mini'
        }]
      }, {
        type: 'select',
        field: 'effect',
        title: '选择提供的主题',
        options: [{
          label: 'light',
          value: 'light'
        }, {
          label: 'dark',
          value: 'dark'
        }, {
          label: 'plain',
          value: 'plain'
        }]
      }

    ];
  }
};