import { dataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
const label = "富文本编辑器";
const name = "yes-editor";

export default {
  icon: "yes-icon-editor",
  label,
  name,
  mask: false,
  rule() {
    return {
      field: uniqueId(),
      type: name,
      title: label,
      props: {
        disabled: false,
        dataSource:""
      },
    };
  },
  props() {
    return [
      dataSource(),
      {
        type: "switch",
        field: "disabled",
        title: "是否禁用状态",
      },
      {
        type: "input",
        field: "app",
        info: "图片上传和选择时生效",
        title: "模型应用编号(必填)",
      },
      {
        type: "input",
        field: "model",
        info: "图片上传和选择时生效",
        title: "模型编号(必填)",
      },
      {
        type: "input",
        field: "model_uid",
        info: "图片上传和选择时生效",
        title: "模型实例主键(必填)",
      },
    ];
  },
};
