import uniqueId from "@form-create/utils/lib/unique";
import { createName } from "../../utils";
const label = "折叠面板";
const name = "yes-collapse";

export default {
  icon: "yes-icon-collapse",
  label,
  name,
  mask: false,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      props: {},
      children: [],
    };
  },
  children: "yes-collapse-item",
  props() {
    return [
      createName(),
      { type: "switch", field: "accordion", title: "是否手风琴模式" },
      {
        type: "input",
        field: "activeName",
        title: "激活的面板(多个用','隔开)",
        placeholder: "请输入激活的面板的",
      },
      {
        type: "yesStruct",
        field: "changeEvent",
        title: "点击事件",
        value: "",
        props: {
          valueType: "function",
        },
      },
    ];
  },
};
