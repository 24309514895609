import { dataSource, viewDataSource, makeOptionsRule } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
const label = "多选框";
const name = "yes-checkbox";

export default {
    icon: "yes-icon-checkbox",
    label,
    name,
    rule() {
        return {
            type: name,
            field: uniqueId(),
            title: label,
            props: {
                dataSource: "",
                viewDataSource: "",
                defaultOptios: []
            }
        };
    },
    props() {
        return [
            dataSource(),
            viewDataSource(),
            {
                type: "switch",
                field: "disable",
                title: "是否只读"
            },
            {
                type: "input",
                field: "itemKey",
                title: "多选框键字段(:key)"
            },
            {
                type: "input",
                field: "itemLable",
                title: "多选框绑定值(:label)"
            },
            {
                type: "input",
                field: "itemValue",
                title: "多选框显示字段(:value)"
            },
            makeOptionsRule([
                { seq: 0, field: "defaultOptios", defaultShow: true },
                { seq: 1, field: "defaultOptios" }
            ]),
            {
                type: "number",
                field: "min",
                title: "最小多选数量"
            },
            {
                type: "number",
                field: "max",
                title: "最大多选数量"
            },
            {
                type: "yesStruct",
                field: "changeEvent",
                title: "change事件",
                props: {
                    valueType: "function"
                }
            }
        ];
    }
};
