import uniqueId from '@form-create/utils/lib/unique';
import { createName } from '../../utils';
const label = '抽屉';
const name = 'yes-drawer';

export default {
  icon: 'yes-icon-drawer',
  label,
  name,
  mask: false,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      props: {
        direction: 'ltr',
      },
      children: [],
    };
  },
  children: 'yes-div',
  props() {
    return [
      createName(),
      { type: 'input', field: 'title', title: '标题' },
      {
        type: 'select',
        field: 'direction',
        title: '抽屉方向',
        options: [
          { label: '从左往右', value: 'ltr' },
          { label: '从右往左', value: 'rtl' },
          { label: '从上往下', value: 'ttb' },
          { label: '从下往上', value: 'btt' },
        ],
      },
      { type: 'input', field: 'size', title: '抽屉宽度' },
      { type: 'switch', field: 'closeOnPressEscape', title: '是否支持esc关闭' },
      { type: 'switch', field: 'destroyOnClose', title: '关闭时销毁子元素' },
      { type: 'switch', field: 'showClose', title: '是否显示关闭按钮' },
      {
        type: 'switch',
        field: 'wrapperClosable',
        title: '是否在点击遮罩层时关闭',
      },
      {
        type: 'yesStruct',
        field: 'beforeClose',
        info: '关闭时的回调方法,可用功能: api,rule,dao,utils',
        title: '关闭前回调',
        props: {
          valueType: 'function',
        },
      },
    ];
  },
};
