import uniqueId from '@form-create/utils/lib/unique';

const label = '表格';
const name = 'yes-table';

export default {
    icon: 'icon-input',
    label,
    name,
    rule() {
        return {
            type: name,
            field: uniqueId(),
            title: label,
            props: {}
        };
    },
    props() { // 表格配置
        return [
            {
                type: 'input',
                field: 'source',
                title: '数据源',
            },{
                type: 'input',
                field: 'clickMethodBody',
                title: '点击事件',
                value:'this.row = row',
                props: {
                type: 'textarea',
                field: 'clickMethodBody',
                rows: 8,
                }
    
            },{
                type: 'input',
                field: 'checkMethodBody',
                title: '选中事件',
                value:'this.treeData = treeData;this.tree = tree',
                props: {
                type: 'textarea',
                rows: 8,
                field: 'checkMethodBody',
                }
    
            },{
            type: 'switch',
            field: 'showSelection',
            title: '启用多选',
        },{
            type: 'switch',
            field: 'showIndex',
            title: '启用序号',
        },{
            type: 'switch',
            field: 'showBorder',
            title: '显示边框'

        },{
            type: 'inputNumber',
            field: 'rowWidth',
            title: '行宽度',
        }];
    }
};
