import { createName } from "../../utils";
const label = "手机组件";
const name = "yes-mobile-componen";

export default {
  icon: "icon-alert",
  label,
  name,
  mask: false,
  rule() {
    return {
      type: name,
      name: "",
      props: {
        type: "default",
      },
    };
  },
  props() {
    return [];
  },
};
