import uniqueId from '@form-create/utils/lib/unique';
import { createName,dataSource,viewDataSource } from '../../utils';
const label = '甘特图';
const name = 'yes-gantt';

export default {
  icon: "yes-icon-gantt",
  label,
  name,
  rule() {
    return {
      name:uniqueId(),
      type: name,
      title: label,
      props: {
        dataSource:"",
        viewDataSource: "",
        fieldConfig:{
          id:{
            field:'',
            title:'项目主键字段'
          },
          code:{
            field:'',
            title:'项目编号',
            width:''
          },
          name:{
            field:'',
            title:'项目名称',
            width:''
          },
          status:{
            field:'',
            title:'项目状态',
            width:''
          },
          leader:{
            field:'',
            title:'项目负责人',
            width:''
          },
          dept:{
            field:'',
            title:'所属部门',
            width:''
          },
          start:{
            field:'',
            title:'开始时间',
            width:''
          },
          end:{
            field:'',
            title:'结束时间',
            width:''
          },
          depends:{
            field:'',
            title:'依赖项目',
            width:''
          }
        },
        iframeHeight:"600px"
      },
    };
  },
  props() {
    return [
      createName(),
      dataSource(),
      viewDataSource(),
      {
        type: "input",
        field: "id",
        title: "主键",
      },
      {
        type: "input",
        field: "nodeId",
        title: "节点编号字段",
      },
      {
        type: "input",
        field: "parentId",
        title: "上级节点编号字段",
      },
      {
        type: "yesStruct",
        field: "fieldConfig",
        title: "字段配置",
        props: {},
        info:'项目状态有6种:进行中:STATUS_ACTIVE,已完成:STATUS_DONE,失败:STATUS_FAILED,暂停:STATUS_SUSPENDED,等待:STATUS_WAITING,未定义:STATUS_UNDEFINED'
      },
      {
        type: "input",
        field: "iframeHeight",
        title: "iframe高度(px)",
      },{
        type: 'yesStruct',
        field: 'dbclickEvent',
        title: '双击事件',
        info:'参数(data,api,dao,utils)',
        props: {
          valueType : 'function'
        }
    },{
        type: 'yesStruct',
        field: 'projectUpdateEvent',
        title: '项目更新事件',
        info:'参数(data,api,dao,utils)',
        props: {
          valueType : 'function'
        }
    }
      
    ];
  },
};
