import { createName, dataSource } from '../../utils';
import uniqueId from '@form-create/utils/lib/unique';
const name = 'yes-timeline-item';

export default {
  name,
  drag: true,
  dragBtn: false,
  inside: true,
  mask: false,
  rule() {
    const id = uniqueId();
    return {
      type: 'yes-timeline-item',
      name: id,
      props: {
        dataSource:""
      },
      children: [],
    };
  },
  props() {
    return [
      createName(),
      dataSource(),
      { type: 'input', field: 'timestamp', title: '时间戳' },
      { type: 'switch', field: 'hideTimestamp', title: '是否隐藏时间戳' },
      {
        type: 'select',
        field: 'placement',
        title: '时间戳位置',
        options: [
          { label: '上', value: 'top' },
          { label: '下', value: 'bottom' },
        ],
      },
      {
        type: 'select',
        field: 'type',
        title: '节点类型',
        options: [
          { value: '默认', label: 'primary' },
          { label: '成功', value: 'success' },
          { label: '警告', value: 'warning' },
          { label: '消息', value: 'info' },
          { label: '错误', value: 'danger' },
        ],
      },
      { type: 'input', field: 'color', title: '节点颜色' },
      { type: 'input', field: 'size', title: '节点尺寸' },
      { type: 'input', field: 'icon', title: '节点图标' },
      { type: 'input', field: 'dot', title: '自定义节点' },
    ];
  },
};
