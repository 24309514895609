import { createName, dataSource } from "../../utils";
const label = "头像";
const name = "yes-avatar";

export default {
  icon: "yes-icon-avatar",
  label,
  name,
  rule() {
    return {
      type: name,
      title: label,
      props: {
        shape: "circle",
        dataSource:""
      },
    };
  },
  props() {
    return [
      createName(),
      dataSource(),
      { type: "input", field: "src", title: "头像的图片地址" },
      { type: "input", field: "size", title: "头像的大小" },
      { type: "select", field: "shape", title: "头像的形状" , options: [{ label: "圆形", value: "circle" }, { label: "方形", value: "square" }]},
      { type: "input", field: "alt", title: "头像的替换文本" },
      { type: "select", field: "fit", title: "头像的适应方式" , options: [{ label: "填充", value: "fill" }, { label: "居中", value: "center" }, { label: "裁剪", value: "cover" }, { label: "none", value: "none" }, { label: "缩小", value: "scale-down" }]},
    ];
  },
};
