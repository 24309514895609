import { dataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
const label = "滑块";
const name = "yes-slider";

export default {
  icon: "yes-icon-slider",
  label,
  name,
  rule() {
    return {
      type: name,
      field: uniqueId(),
      title: label,
      props: {
        dataSource:""
      },
    };
  },
  props() {
    return [
      dataSource(),
      {
        type: "switch",
        field: "showTooltip",
        title: "是否显示tooltip",
      },
      {
        type: "switch",
        field: "disabled",
        title: "是否只读",
      },
      {
        type: "inputNumber",
        field: "step",
        title: "步长",
      },
      {
        type: "switch",
        field: "showStop",
        title: "是否显示断点",
      },
      {
        type: "inputNumber",
        field: "min",
        title: "最小值",
      },
      {
        type: "inputNumber",
        field: "max",
        title: "最大值",
      },
      {
        type: "switch",
        field: "showInput",
        title: "是否显示输入框",
      },
    ];
  },
};
