const name = 'yes-data-array';
import { createName, dataSource } from '../../utils';
export default {
    icon: "yes-icon-data-array",
    label: '数据列表',
    name,
    drag: true,
    mask: false,
    rule() {
        return {
            type: name,
            props: {
                dataSource: ''
            },
            children: []
        };
    },
    children: 'yes-div',
    props() {
        return [
            createName(),
            dataSource(),
            { type: "input", field: "repeat", title: "循环组件编号" },
        ]
    },

};
