import uniqueId from '@form-create/utils/lib/unique';
import { createName } from '../../utils';
const label = '栅格布局';
const name = 'yes-row';

export default {
  icon: 'yes-icon-row',
  label,
  name,
  mask: false,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      props: {},
      children: [],
    };
  },
  children: 'yes-row-col',
  props() {
    return [
      createName(),
      { type: 'inputNumber', field: 'gutter', title: '栅格间隔' },
      {
        type: 'switch',
        field: 'type',
        title: 'flex布局模式',
        props: { activeValue: 'flex', inactiveValue: 'default' },
      },
      {
        type: 'select',
        field: 'justify',
        title: 'flex 布局下的水平排列方式',
        options: [
          { label: 'start', value: 'start' },
          { label: 'end', value: 'end' },
          {
            label: 'center',
            value: 'center',
          },
          { label: 'space-around', value: 'space-around' },
          { label: 'space-between', value: 'space-between' },
        ],
      },
      {
        type: 'select',
        field: 'align',
        title: 'flex 布局下的垂直排列方式',
        options: [
          { label: 'top', value: 'top' },
          { label: 'middle', value: 'middle' },
          {
            label: 'bottom',
            value: 'bottom',
          },
        ],
      },
      {
        type: 'input',
        field: 'width',
        title: '宽度',
      },
      {
        type: 'input',
        field: 'height',
        title: '高度',
      },
      {
        type: 'upload',
        field: 'image',
        title: '上传',
        props: {
          size: 'small',
          type: 'primary',
          limit: 1,
          action: '',
          listType: 'picture',
          autoUpload: false,
          showFileList: true,
        },
      },
      {
        type: 'select',
        field: 'size',
        title: '尺寸',
        options: [
          { label: '100px 100px', value: '100px 100px' },
          { label: '75px 75px', value: '75px 75px' },
          { label: '10px 150px', value: '10px 150px' },
          { label: '200px', value: '200px' },
          { label: '50%', value: '50%' },
          { label: '100% 100%', value: '100% 100%' },
          { label: 'cover', value: 'cover' },
          { label: 'contain', value: 'contain' },
        ],
      },
      {
        type: 'input',
        field: 'marginTop',
        title: '上边距',
      }
    ];
  },
};
