import uniqueId from "@form-create/utils/lib/unique";
import { createName } from "../../utils";
const label = "空状态";
const name = "yes-empty";

export default {
  icon: "yes-icon-empty",
  label,
  name,
  mask: false,
  rule() {
    return {
      type: name,
      name: uniqueId(),
      props: {},
      children: [],
    };
  },
  props() {
    return [
      createName(),
      { type: "input", field: "description", title: "描述", props: { type: 'textarea', rows: 8, placeholder: "请输入描述" } },
      { type: "input", field: "image", title: "图片地址" },
      { type: "inputNumber", field: "imageSize", title: "图片大小(宽度)" },
    ];
  },
};
