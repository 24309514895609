import { createName, dataSource } from "../../utils";
const label = "图片";
const name = "yes-image";

export default {
  icon: "yes-icon-image",
  label,
  name,
  rule() {
    return {
      name: "",
      type: name,
      title: label,
      props:{
        dataSource: "",
        showSrc: true,
        url:""
      }
    };
  },
  props() {
    return [
      createName(),
      dataSource(),
      {
        type: "input",
        field: "url",
        title: "图片地址",
        info: "如果图片地址有设置，数据源的图片地址不存在，则展示图片地址所设置的自定义图片，若果图片地址未定义，则显示默认图片",
      },
      {
        type: "input",
        field: "width",
        title: "宽度",
      },
      {
        type: "input",
        field: "height",
        title: "高度",
      },
      {
        type: "select",
        field: "type",
        title: "显示比例属性类型(object-fit)",
        options: [
          {
            label: "fill",
            value: "fill",
          },
          {
            label: "contain",
            value: "contain",
          },
          {
            label: "cover",
            value: "cover",
          },
          {
            label: "scale-down",
            value: "scale-down",
          },
          {
            label: "none",
            value: "none",
          },
          {
            label: "initial",
            value: "initial",
          },
          {
            label: "inherit",
            value: "inherit",
          },
        ],
      },
      {
        type: "switch",
        field: "showSrc",
        title: "是否点击大图显示"
      },
      {
        type: "yesStruct",
        field: "clickEvent",
        title: "点击事件",
        props: {
          valueType : 'function'
        }
      },
    ];
  },
};
