import { createName } from '../../utils';
import uniqueId from '@form-create/utils/lib/unique';
const label = '面包屑';
const name = 'yes-breadcrumb';

export default {
    icon: 'yes-icon-breadcrumb',
    label,
    name,
    children: 'yes-breadcrumb-item',
    mask:false,
    rule() {
        return {
            type: name,
            name: uniqueId(),
            title: label,
            props: {},
            children: [],
        };
    },
    props() { // 表格配置
        return [
            createName(),
            { type: 'input', field: 'separator', title: '分隔符' },
            { type: 'input', field: 'separatorClass', title: '分隔符样式' },
        ];
    }
};
