import { dataSource } from "../../utils";
import uniqueId from "@form-create/utils/lib/unique";
const label = "级联选择器";
const name = "yes-cascader";

export default {
    icon: "yes-icon-cascader",
    label,
    name,
    rule() {
        return {
            type: name,
            title: label,
            field: uniqueId(),
            props: {
                showLevels: true,
                dataSource: "",
                placeholder: "请选择",
                separator: " / ",
                disabled: false,
                clearable: false,
                collapseTags: false,
                debounce: 300,
                itemProp: {
                    expandTrigger: "click",
                    multiple: false,
                    checkStrictly: false,
                    emitPath: true,
                    lazy: false,
                    value: "value",
                    label: "label",
                    children: "children",
                    disabled: "disabled",
                    leaf: "leaf",
                },
            },
        };
    },
    props() {
        return [
            dataSource(),
            {
                type: "input",
                field: "placeholder",
                title: "输入框占位文本",
            },
            {
                type: "input",
                field: "optionSource",
                title: "选项绑定数据",
            },
            {
                type: "input",
                field: "separator",
                title: "选项分隔符",
            },
            { type: "inputNumber", field: "debounce", title: "搜索关键词输入的去抖延迟，毫秒" },
            {
                type: "yesStruct",
                field: "itemProp",
                title: "级联选择器props参数",
                info: "https://element.eleme.cn/#/zh-CN/component/cascader#props",
                props: {
                    type: "textarea",
                    rows: 8,
                },
                value: {
                    expandTrigger: "click",
                    multiple: false,
                    checkStrictly: false,
                    emitPath: true,
                    lazy: false,
                    value: "value",
                    label: "label",
                    children: "children",
                    disabled: "disabled",
                    leaf: "leaf",
                },
            },
            {
                type: "yesStruct",
                field: "filterMethod",
                title: "自定义搜索逻辑",
                props: {
                    valueType: "function",
                },
            },
            {
                type: "switch",
                field: "disabled",
                title: "是否只读",
            },
            {
                type: "select",
                field: "size",
                title: "尺寸",
                options: [
                    {
                        label: "medium",
                        value: "medium",
                    },
                    {
                        label: "small",
                        value: "small",
                    },
                    {
                        label: "mini",
                        value: "mini",
                    },
                ],
            },
            {
                type: "switch",
                field: "filterable",
                title: "是否可搜索选项",
            },
            {
                type: "switch",
                field: "clearable",
                title: "是否可清空",
            },
            {
                type: "switch",
                field: "showLevels",
                title: "输入框中是否显示选中值的完整路径",
            },
            {
                type: "switch",
                field: "collapseTags",
                title: "多选模式下是否折叠Tag",
            },
            {
                type: "yesStruct",
                field: "changeEvent",
                title: "change事件",
                props: {
                    valueType: "function",
                },
            },
        ];
    },
};
