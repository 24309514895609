import { createName, dataSource } from "../../utils";
const label = '带任务的日历';
const name = 'yesItemCalendar';

export default {
    icon: 'icon-button',
    label,
    name,
    mask: false,
    rule() {
        return {
            type: name,
            props: {
                dataSource: ""
            }
          };
    },
    props() {
        return [
          createName(),
          dataSource(),
          {
            type: 'yesStruct',
            field: 'clickEvent',
            title: '点击网格中的事件后的操作事件',
            info: '点击某一个网格中的事件后触发，若自定义弹窗自行操作',
            value: '',
            props: {
                valueType: 'function'
            }
        },
        ];
      },
};
