import uniqueId from "@form-create/utils/lib/unique";
import { createName } from "../../utils";

const label = "标签页";
const name = "yes-tabs";

export default {
  icon: "yes-icon-tabs",
  label,
  name,
  children: "yes-tabs-pane",
  mask: false,
  rule() {
    return {
      name: uniqueId(),
      type: name,
      props: {
        maxLength: 5,
      },
      children: [],
    };
  },
  props() {
    return [
      createName(),
      {
        type: "select",
        field: "type",
        title: "风格类型",
        options: [
          { label: "default", value: "default" },
          {
            label: "card",
            value: "card",
          },
          { label: "border-card", value: "border-card" },
        ],
      },
      //   { type: 'switch', field: 'closable', title: '标签是否可关闭' },
      {
        type: "select",
        field: "tabPosition",
        title: "选项卡所在位置",
        options: [
          { label: "top", value: "top" },
          { label: "right", value: "right" },
          {
            label: "left",
            value: "left",
          },
        ],
      },
      { type: "switch", field: "stretch", title: "标签的宽度是否自撑开" },
      { type: "inputNumber", field: "maxLength", title: "最大标签数" },
      {
        type: "yesStruct",
        field: "tabClickEvent",
        title: "点击事件",
        value: "",
        props: {
          valueType: "function",
        },
      },
      { type: "input", field: "width", title: "Iframe宽度" },
      { type: "input", field: "height", title: "Iframe高度" },
      { type: "input", field: "fontFamily", title: "Iframe统一字体", info: "定义在body标签, 不允许后缀添加 !important, 单纯写字体值" },
      { type: 'input', field: 'fontFamilyTime', title: 'body字体赋值延时', info: '根据性能而定' }
    ];
  },
};
