import { createName } from "../../utils";
const label = "消息提示";
const name = "yes-message";

export default {
  icon: "icon-alert",
  label,
  name,
  mask: false,
  rule() {
    return {
      type: name,
      name: "",
      props: {
        type: "default",
      },
    };
  },
  props() {
    return [createName()];
  },
};
